import ValueContainer from '../../utils/value_container';
import useObservable from '../../utils/observable/use_observable_hook';
import styles from './level_progress_bar.module.css';

type LevelProgressBarProps = {
    width: number;
    height: number;
    borderRadius?: number;
    backgroundColor?: string;
    progressColor?: string;
};

const LevelProgressBar = ({
    width,
    height,
    borderRadius = height / 2,
    backgroundColor = 'var(--primary-color)',
    progressColor = 'var(--gold-color)',
}: LevelProgressBarProps) => {
    const currentLevelExperience = useObservable(
        ValueContainer.currentLevelExperienceObservable
    );
    const experienceUntilNextLevel = useObservable(
        ValueContainer.experienceUntilNextLevelObservable
    );

    const experienceGained =
        (currentLevelExperience ?? 1) - (experienceUntilNextLevel ?? 1);
    let experienceProgress = experienceGained / (currentLevelExperience ?? 1);

    experienceProgress = Math.max(0, Math.min(1, experienceProgress));

    return (
             
        <div className={styles.levelBar} style={{
                width: `${width}%`,
                height,
                borderRadius,
            }}>
            <div className={styles.bar} style={{ width: `${experienceProgress * 100}%`}}></div>
        </div>
   
    );
};

export default LevelProgressBar;
