class CryptoCurrencyInfo {
    name: string;
    description: string;
    coverUrl: string;
    cryptoCurrency: string;
    cryptoCurrencyAmount: number | undefined;

    constructor(
        name: string,
        description: string,
        coverUrl: string,
        cryptoCurrency: string,
        cryptoCurrencyAmount?: number
    ) {
        this.name = name;
        this.description = description;
        this.coverUrl = coverUrl;
        this.cryptoCurrency = cryptoCurrency;
        this.cryptoCurrencyAmount = cryptoCurrencyAmount;
    }

    static fromJsonUpperCamelCase(json: {
        [key: string]: any;
    }): CryptoCurrencyInfo {
        return new CryptoCurrencyInfo(
            json['Name'],
            json['Description'],
            json['CoverUrl'],
            json['CryptoCurrency'],
            json['CryptoCurrencyAmount']
        );
    }

    static fromJsonLowerCamelCase(json: {
        [key: string]: any;
    }): CryptoCurrencyInfo {
        return new CryptoCurrencyInfo(
            json['name'],
            json['description'],
            json['coverUrl'],
            json['cryptoCurrency'],
            json['cryptoCurrencyAmount']
        );
    }
}

export default CryptoCurrencyInfo;
