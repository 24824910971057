import { constants } from '../../utils/constants';
import StandardHttpAPI from '../standard_http_api';
import ValueContainer from '../../utils/value_container';
import CompactJsonOffer from '../offer_api/compact_json_offer';
import AcquiredOffer from '../offer_api/acquired_offer';
import StandardHttpResponse from '../models/standard_http_response';

const API_URL = constants.API_URL;

class BubbleApi {
    // LAST_PLAYABLE_TOO_FAR_AWAY - Only happens if the user has since reached the bubble illegally. If they reached it legally
    // the last position should be in range of the bubble
    // INVALID_PLAYABLE_GROUP - Only happens if user never had a playable group before
    /**
     * Check if the user can open a bubble
     * @param bubbleId - The bubble id
     * @returns A promise that resolves to a StandardHttpResponse
     */
    static async canOpenBubble(
        bubbleId: number | string
    ): Promise<StandardHttpResponse> {
        return await StandardHttpAPI.get({
            uri: `${API_URL}bubble/${bubbleId}/canOpen`,
            bearer: ValueContainer.token,
        });
    }

    static async getOffersForBubble(
        bubbleId: number | string
    ): Promise<CompactJsonOffer[]> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}bubble/${bubbleId}/offers`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.map((json: any) =>
            CompactJsonOffer.fromJsonLowerCamelCase(json)
        );
    }

    static async purchaseOffer(offerId: string): Promise<AcquiredOffer> {
        const response = await StandardHttpAPI.post({
            uri: `${API_URL}bubble/purchase/${offerId}`,
            bearer: ValueContainer.token,
            body: undefined,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return AcquiredOffer.fromJsonLowerCamelCase(response.response);
    }
}

export default BubbleApi;
