import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './dig_window.module.css';
import ParticlesComponent from './particles';
import Images from '../../../component_system/components/preload/images';
import useFetchComponent from '../../../component_system/component_container/utilities/use_fetch_hook';
import ContainerHelper from '../../../component_system/component_container/utilities/container_helper';
import CompactJsonOffer from '../../../apis/offer_api/compact_json_offer';
import { rarity_gradient } from '../../../utils/constants';
import formatNumber from '../../../utils/currency_formatter';

type DigWindowProps = {
    onView?: () => void;
    onClose?: () => void;
};

const DigWindow: React.FC<DigWindowProps> = ({ onView, onClose }) => {
    const offerComponent = useFetchComponent(
        ContainerHelper.getOffersComponent
    );
    const minedOffer = offerComponent?.minedOffer;
    const focusedBubble = offerComponent?.focusedBubble;
    const rarity: keyof typeof Images.StoneStates =
        (focusedBubble?.metadata![
            'Rarity'
        ] as keyof typeof Images.StoneStates) || 'Common';

    const [currentState, setCurrentState] = useState<number>(0);
    const [showOre, setShowOre] = useState<boolean>(true);
    const [progress, setProgress] = useState<number>(0);
    const progressThreshold = 2;
    const progressDecayRate = 0.5;
    const digWindowRef = useRef<HTMLDivElement>(null);
    const oreHolderRef = useRef<HTMLDivElement>(null);
    const oreImageCount = 7;

    useCallback(
        (offer: CompactJsonOffer) => {
            offerComponent?.purchaseOffer(offer.offerId);
        },
        [offerComponent]
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => Math.max(prev - progressDecayRate, 0));
        }, 500);

        return () => clearInterval(interval);
    }, []);

    const audio = useRef(new Audio(require('../../../assets/sounds/dig.wav')));

    const playTapSound = useCallback(() => {
        // noinspection JSIgnoredPromiseFromCall
        audio.current.play();
    }, [audio]);

    const handleClick = useCallback(
        (_event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (showOre) {
                oreHolderRef.current?.classList.add(styles.oreHolderTapped);
                digWindowRef.current?.classList.add(styles.tapped);
                setTimeout(() => {
                    oreHolderRef.current?.classList.remove(
                        styles.oreHolderTapped
                    );
                    digWindowRef.current?.classList.remove(styles.tapped);
                }, 100);

                playTapSound();

                setProgress((prev) => prev + 1);

                if (progress + 1 >= progressThreshold) {
                    setProgress(0);
                    setCurrentState((prev) => {
                        if (prev >= oreImageCount - 1) {
                            // Show reward screen when stone is completely cracked
                            setShowOre(false);
                            showRewardScreen();
                            return prev;
                        } else {
                            return prev + 1;
                        }
                    });
                }
            }
        },
        [showOre, playTapSound, progress]
    );

    const showRewardScreen = () => {};

    const [isImageLoaded, setIsImageLoaded] = useState(false);
    function handleImageLoad(): void {
        setIsImageLoaded(true);
    }

    return (
        <div
            className={styles.digWindow}
            style={{
                // @ts-ignore
                background: rarity_gradient['Rare'], //rarity_gradient[rarity]  //We hardcode to the default color
            }}
            ref={digWindowRef}
            onClick={showOre && minedOffer ? handleClick : undefined}
        >
            <div className={styles.spinnerBackground}></div>
            <img
                src={Images.Backgrounds.AnimatedBackground}
                className={styles.animatedImage}
                alt=""
            />
            {!showOre && minedOffer && (
                <div id="reward" className={styles.rewardHolder}>
                    <img
                        src={minedOffer.coverUrl}
                        className={styles.rewardImg}
                        onLoad={() =>handleImageLoad()}
                        alt=""
                    />
                    <h3 className={styles.rewardText}>
                        <small className={styles.rewardTextSmall}>
                            You dug up
                        </small>
                        <br />
                        {`${formatNumber(minedOffer.cryptoCurrencyAmount)} ${minedOffer.cryptoCurrency}`}
                    </h3>
                    <button
                        className={styles.closeButton}
                        name="button"
                        onClick={onView}                       
                        disabled={!isImageLoaded}
                        style={{
                            backgroundImage: `url(${Images.Backgrounds.ButtonOrangeBig})`,
                        }}
                    >
                        Claim
                    </button>
                </div>
            )}
            {showOre && minedOffer && (
                <>
                    <img
                        src={Images.Panels.MineBanner}
                        className={styles.banner}
                        alt=""
                    />
                    <div className={styles.oreHolder} ref={oreHolderRef}>
                        <img
                            src={
                                (
                                    Images.StoneStates[rarity] as {
                                        [key: string]: string;
                                    }
                                )['stage_' + currentState]
                            }
                            className={`${styles.ore} ${styles[`state_${currentState}`]}`}
                            style={{ display: showOre ? 'block' : 'none' }}
                            alt=""
                        />
                    </div>

                    <div className={styles.textHolder}>
                        <p className={styles.digText}>TAP TO DIG</p>
                    </div>

                    <ParticlesComponent />
                </>
            )}
        </div>
    );
};

export default DigWindow;
