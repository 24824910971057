import ProgressInfo from './models/progress_info';
import Component from './models/component';
import ComponentError from './models/component_error';
import DependencyLockInfo from './models/dependency_lock_info';
import UpdateCallback from './types/update_callback';
import LoadStatus from './models/load_status';
import Stopwatch from './utilities/stopwatch';
import { Duration, TimeDuration } from 'typed-duration';
import Completer from './utilities/completer';
import AutoQueue from './utilities/auto_queue';
import AppLifecycleState from './enums/app_lifecycle_state';
import ComponentErrorType from './enums/component_error_type';
import { component_error_codes } from '../../utils/constants';

const { milliseconds } = Duration;

class ComponentContainer {
    private static _instance: ComponentContainer | null = null;
    public static get instance(): ComponentContainer | null {
        return this._instance;
    }

    private _updateTimer!: NodeJS.Timeout;
    private _initialized = false;
    private _updateTimerRunning = false;
    private _updateTimerShouldStart = true;
    private _loaded = false;
    private _isDirty = false;
    private _componentsLoaded = 0;
    private _progressReportQueue!: AutoQueue<ProgressInfo>;
    private _lastUpdate!: Date;
    private _loadCheckTimer?: NodeJS.Timeout;

    private _absoluteLoadCompleter = new Completer<void>();
    private _currentLoadCompleter = new Completer<void>();

    private _componentErrors: Map<Component, ComponentError[]> = new Map();
    private _componentDependencyLockInfo: Map<Component, DependencyLockInfo> =
        new Map();
    private _pauseAfterLoad: Component[] = [];
    private _dependencyLockCompleters: Map<Array<Function>, Completer<void>> =
        new Map();

    private _timerTargetDuration = 1000 / 2;
    private _loadCheckTimerDuration = 100;

    private _onProgress: (progressInfo: ProgressInfo) => void = () => {};
    private _updateCallbacks: UpdateCallback[] = [];

    private _componentLoaded: Map<Component, boolean> = new Map();
    private _componentLoading: Map<Component, boolean> = new Map();
    private _componentDependencyLockSpendTime: Map<Component, number> =
        new Map();
    private _componentLoadSpendTime: Map<Component, number> = new Map();
    private _loadCompleters: Map<Function, Array<Completer<Component>>> =
        new Map();
    private _askForPermissionLock: Map<Component, boolean> = new Map();

    private _components: Component[];

    constructor(components: Component[]) {
        ComponentContainer._instance = this;
        this._components = components;
        for (let component of this._components) {
            component.container = this;
            this._componentLoaded.set(component, false);
            this._loadCompleters.set(component.type, []);
            this._componentErrors.set(component, []);
        }
    }

    static set instance(instance: ComponentContainer | null) {
        this._instance = instance;
    }

    get loaded(): boolean {
        return this._loaded;
    }

    get isDirty(): boolean {
        return this._isDirty;
    }

    get makeSureLoaded(): Completer<void> {
        return this._absoluteLoadCompleter;
    }

    set onProgress(callback: (progressInfo: ProgressInfo) => void) {
        this._onProgress = callback;
    }

    registerUpdateCallback(callback: UpdateCallback): void {
        this._updateCallbacks.push(callback);
    }

    unregisterUpdateCallback(callback: UpdateCallback): void {
        this._updateCallbacks = this._updateCallbacks.filter(
            (cb) => cb !== callback
        );
    }

    reportProgress(
        component: Component,
        progressInfo: ProgressInfo,
        minDisplayTime: TimeDuration
    ): void {
        progressInfo.componentName = component.name;
        progressInfo.componentLoadIndex = this._componentsLoaded + 1;
        progressInfo.totalComponentCount = this._components.length;
        this._progressReportQueue.enqueue(progressInfo, minDisplayTime.value);
    }

    componentLoadedStatus(): Map<string, LoadStatus> {
        const result = new Map<string, LoadStatus>();
        this._components.forEach((component) => {
            result.set(
                component.name,
                new LoadStatus(
                    this._componentLoaded.get(component) ?? false,
                    this._dependencyLocked(component)
                )
            );
        });
        return result;
    }

    componentIsLoaded(component: Component): boolean | undefined {
        return this._componentLoaded.get(component);
    }

    componentIsLoadedByType(componentType: Function): boolean {
        const component = this._components.find(
            (c) => c.constructor === componentType
        );
        return component
            ? (this._componentLoaded.get(component) ?? false)
            : false;
    }

    private _tryStartLoadCheckTimer(): void {
        if (!this._loadCheckTimer) {
            this._loadCheckTimer = setInterval(() => {
                if (this._loaded) {
                    clearInterval(this._loadCheckTimer!);
                    this._loadCheckTimer = undefined;
                } else {
                    this._checkForUnreportedErrors();
                }
            }, this._loadCheckTimerDuration);
        }
    }

    private _retryLoad(): Completer<void> {
        this._currentLoadCompleter = new Completer<void>();
        this._load();
        return this._currentLoadCompleter;
    }

    tryRetry(): Completer<void> {
        const hasLoadErrors = Array.from(this._componentErrors.values()).some(
            (errors) =>
                errors.length > 0 &&
                errors[0].errorType === ComponentErrorType.LoadError
        );

        if (this._loaded || !this._initialized || !hasLoadErrors) {
            const errorCompleter = new Completer<void>();

            const loadErrorsInfo: Map<string, ComponentError[]> = new Map();

            loadErrorsInfo.set('Container', [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'containerAlreadyInitialized'.tr(),
                    undefined,
                    component_error_codes.CONTAINER_ALREADY_INITIALIZED
                ),
            ]);

            errorCompleter.reject(loadErrorsInfo);
            return errorCompleter;
        } else {
            return this._retryLoad();
        }
    }

    tryInitialize(): Completer<void> {
        this._isDirty = true;

        if (this._initialized) {
            const errorCompleter = new Completer<void>();
            const loadErrorsInfo: Map<string, ComponentError[]> = new Map();

            loadErrorsInfo.set('Container', [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'containerAlreadyInitialized'.tr(),
                    undefined,
                    component_error_codes.CONTAINER_ALREADY_INITIALIZED
                ),
            ]);

            errorCompleter.reject(loadErrorsInfo);
            return errorCompleter;
        }

        this._initialized = true;

        this._tryStartLoadCheckTimer();

        return this._firstLoad();
    }

    private _firstLoad(): Completer<void> {
        this._currentLoadCompleter = new Completer<void>();
        this._load();
        return this._currentLoadCompleter;
    }

    private _startTimer(): void {
        if (!this._loaded) {
            this._updateTimerShouldStart = true;
            return;
        }
        if (!this._updateTimerRunning) {
            this._lastUpdate = new Date();
            this._updateTimer = setInterval(() => {
                const now = new Date();
                const diff = now.getTime() - this._lastUpdate.getTime();
                this._components.forEach((component) =>
                    component.update(milliseconds.of(diff))
                );
                this._updateCallbacks.forEach((callback) =>
                    callback(milliseconds.of(diff))
                );
                this._lastUpdate = now;
            }, this._timerTargetDuration);
            this._updateTimerRunning = true;
        }
    }

    private _stopTimer(): void {
        if (this._updateTimerRunning) {
            clearInterval(this._updateTimer);
            this._updateTimerRunning = false;
        } else if (!this._loaded) {
            this._updateTimerShouldStart = false;
        }
    }

    private _load(): Promise<void> {
        const progressLock = new Promise<void>(() => {});
        const mainStopwatch = new Stopwatch();
        mainStopwatch.start();
        this._progressReportQueue = new AutoQueue<ProgressInfo>(
            200,
            2000,
            (progressInfo: ProgressInfo) => this._onProgress(progressInfo),
            () => progressLock
        );

        for (let i = 0; i < 6; i++) {
            this._progressReportQueue.enqueue(
                new ProgressInfo(`friendlyLoadMessage${i}`.tr()),
                3500
            );
        }

        this._progressReportQueue.start();

        for (let component of this._components) {
            if (
                !this._componentLoading.get(component) &&
                !this._componentLoaded.get(component)
            ) {
                this._componentLoaded.set(component, false);
                this._componentErrors.set(component, []);
                this._componentDependencyLockInfo.set(
                    component,
                    new DependencyLockInfo([])
                );
                this._componentLoading.set(component, true);
                this._componentDependencyLockSpendTime.set(component, 0);
                this._componentLoadSpendTime.set(component, 0);
                this._loadComponent(component, mainStopwatch);
            }
        }
        return progressLock;
    }

    private async _loadComponent(
        component: Component,
        mainStopwatch: Stopwatch
    ): Promise<void> {
        const stopwatch = new Stopwatch();
        stopwatch.start();
        const errors = await component.load();
        this._componentLoading.set(component, false);
        stopwatch.stop();
        this._componentLoadSpendTime.set(component, stopwatch.elapsed);

        if (errors.length > 0) {
            this._componentErrors.set(component, errors);
            this._componentLoaded.set(component, false);
            console.error(`${component.name} failed to load`);
            if (
                this._components.every(
                    (c) =>
                        this._componentErrors.get(c)?.length ||
                        this._dependencyLocked(c) ||
                        this._componentLoaded.get(c)
                )
            ) {
                this._reportLoadErrors();
            }
        } else {
            this._componentLoaded.set(component, true);
            this._componentsLoaded++;
            if (this._pauseAfterLoad.includes(component)) {
                await component.pause();
                this._pauseAfterLoad = this._pauseAfterLoad.filter(
                    (c) => c !== component
                );
            }
            console.info(
                `${component.name} loaded in ${stopwatch.elapsed}ms, with ${this._componentDependencyLockSpendTime.get(component)}ms spent on dependency locks`
            );

            for (const completer of this._loadCompleters.get(component.type) ||
                []) {
                completer.resolve(component);
            }

            this._loadCompleters.set(component.type, []);
            for (const dli of this._componentDependencyLockInfo.values()) {
                dli.removeDependency(component.type);
            }

            for (const [
                types,
                completer,
            ] of this._dependencyLockCompleters.entries()) {
                if (
                    types.every((type) =>
                        this._componentLoaded.get(
                            this._getComponentWithType(type)!
                        )
                    )
                ) {
                    completer.resolve();
                } else {
                    console.error(
                        `Dependency lock completer for ${types.map((t) => t.name)} not resolved`
                    );
                }
            }

            if (this._components.every((c) => this._componentLoaded.get(c))) {
                this._loaded = true;
                mainStopwatch.stop();
                console.info(
                    `All components loaded in ${mainStopwatch.elapsed}ms`
                );
                this._absoluteLoadCompleter.resolve();
                this._currentLoadCompleter.resolve();

                if (this._updateTimerShouldStart) {
                    this._startTimer();
                }
            } else {
                if (
                    this._components.every(
                        (component) =>
                            (this._componentErrors.get(component)?.length ??
                                0) > 0 ||
                            this._dependencyLocked(component) ||
                            this._componentLoaded.get(component) === true
                    )
                ) {
                    this._reportLoadErrors();
                }
            }
        }
    }

    private _dependencyLocked(component: Component): boolean {
        return !this._componentDependencyLockInfo
            .get(component)!
            .dependencies.every((type) =>
                this._componentLoaded.get(this._getComponentWithType(type)!)
            );
    }

    private _getComponentWithType(type: Function): Component | undefined {
        return this._components.find((component) => component.type === type);
    }

    forceGet(type: Function): Component {
        return this._getComponentWithType(type)!;
    }

    async getComponent(type: Function): Promise<Component> {
        if (
            this._componentLoaded.get(this._getComponentWithType(type)!) ??
            false
        ) {
            return this._getComponentWithType(type)!;
        } else {
            const completer = new Completer<Component>();
            this._loadCompleters.get(type)?.push(completer);
            return completer.promise;
        }
    }

    private _checkForUnreportedErrors(): void {
        if (this._currentLoadCompleter.resolved) return;
        if (this._loaded) return;
        if (
            this._components.every(
                (component) =>
                    this._componentErrors.get(component)?.length ||
                    this._dependencyLocked(component) ||
                    this._componentLoaded.get(component)
            )
        ) {
            this._reportLoadErrors();
        }
    }

    private _reportLoadErrors(): void {
        const loadErrorsInfo: Map<string, ComponentError[]> = new Map();

        for (const [component, errors] of this._componentErrors.entries()) {
            loadErrorsInfo.set(component.name, errors);
        }
        this._currentLoadCompleter.reject(loadErrorsInfo);
    }

    async setDependencyLocked(
        component: Component,
        dependencies: Array<Function>
    ): Promise<void> {
        const stopwatch = new Stopwatch();
        stopwatch.start();

        this._componentDependencyLockInfo
            .get(component)!
            .setDependencies(dependencies);
        const completer = new Completer<void>();
        this._dependencyLockCompleters.set(dependencies, completer);

        completer.then(() => {
            stopwatch.stop();
            this._componentDependencyLockSpendTime.set(
                component,
                (this._componentDependencyLockSpendTime.get(component) ?? 0) +
                    stopwatch.elapsed
            );
        });

        if (
            dependencies.every((type) =>
                this._componentLoaded.get(this._getComponentWithType(type)!)
            )
        ) {
            completer.resolve();
        }

        return completer;
    }

    async setAskForPermissionLock(
        component: Component,
        locked: boolean
    ): Promise<void> {
        if (!locked) {
            this._askForPermissionLock.set(component, locked);
            return;
        }

        while (
            Array.from(this._askForPermissionLock.values()).some((val) => val)
        ) {
            await new Promise((resolve) => setTimeout(resolve, 100));
        }

        this._askForPermissionLock.set(component, locked);
    }

    async unload(): Promise<void> {
        await this.setAppLifecycleState(AppLifecycleState.Paused);
        for (let component of this._components) {
            await component.unload();
        }
    }

    async setAppLifecycleState(state: AppLifecycleState): Promise<void> {
        if (state === AppLifecycleState.Paused) {
            this._stopTimer();
        } else if (state === AppLifecycleState.Resumed) {
            this._startTimer();
        }

        for (let component of this._components) {
            if (state === AppLifecycleState.Resumed) {
                if (this._componentLoaded.get(component)!)
                    await component.resume();
                else
                    this._pauseAfterLoad = this._pauseAfterLoad.filter(
                        (c) => c !== component
                    );
            } else if (state === AppLifecycleState.Paused) {
                if (this._componentLoaded.get(component)!)
                    await component.pause();
                else this._pauseAfterLoad.push(component);
            }
        }
    }
}

export default ComponentContainer;
