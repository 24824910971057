import React from 'react';
import ReactModal from 'react-modal';
import styles from './location_permission_popup.module.css';
import Images from "../../../component_system/components/preload/images";
import { confirmable, ConfirmDialog, createConfirmation } from "react-confirm";

interface LocationPermissionPopupProps {
    show: boolean;
    onAccept: () => void;
}

const LocationPermissionPopup: React.FC<LocationPermissionPopupProps> = ({
                                                                             show,
                                                                             onAccept,
                                                                         }) => {
    return (
        <ReactModal
            isOpen={show}
            style={{
                overlay: {
                    zIndex: 3,
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                },
                content: {
                    background: `url(${Images.Backgrounds.Background}) center center / cover no-repeat`,
                    border: '1px solid var(--primary-color)',
                    borderRadius: '8px',
                    padding: '20px',
                    // maxWidth: '400px',
                    margin: 'auto',
                    height: 'fit-content',
                },
            }}
        >
            <h1 className={styles.popupTitle}>Location Required</h1>
            <div
                 style={{backgroundImage: `url(${Images.Backgrounds.LocationRequiredHero})`}}
                 className={styles.popupImage}/>
            <p className={styles.popupMessage}>
                To enjoy the game, enabling location services is necessary.
            </p>
            <ul className={styles.benefitsList}>
                <li>Unlock location-based quests</li>
                <li>Discover exclusive in-game items near you</li>
                <li>Connect and play with nearby players</li>
            </ul>
            <p className={styles.privacyNote}>
                Your location is essential for gameplay and will only be used to enhance your experience. We do not share your data with third parties.
            </p>
            <div className={styles.popupButtons}>
                <button
                    className={styles.acceptButton}
                    onClick={onAccept}
                >
                    Enable Location
                </button>
            </div>
        </ReactModal>
    );
};

type LocationPermissionDialogProps = {
    options: {
        onConfirm?: () => boolean;
    };
};

export const LocationPermissionDialog: ConfirmDialog<
    LocationPermissionDialogProps,
    boolean
> = ({
         show,
         proceed,
         options: {
             onConfirm = undefined,
         },
     }) => {
    return (
        <LocationPermissionPopup
            show={show}
            onAccept={() => {
                if (onConfirm) {
                    if (onConfirm()) {
                        proceed(true);
                    }
                }
                proceed(true);
                return true;
            }}
        />
    );
};

const locationPermissionDialog = confirmable(LocationPermissionDialog);

export const confirmLocationPermissionDialog = createConfirmation(locationPermissionDialog);