import styles from './user_avatar.module.css';
import ValueContainer from '../../utils/value_container';
import useObservable from '../../utils/observable/use_observable_hook';
import Images from '../../component_system/components/preload/images';
import { useEffect, useState } from 'react';

type UserAvatarProps = {
    size: number;  
    showLevel?: boolean;
    levelTextColor?: string;
    imageUri?: string;
};

// TODO: move styling to separate file
const UserAvatar = ({
    size,
    showLevel = true,
    levelTextColor = 'white',
    imageUri,
}: UserAvatarProps) => {
    const currentLevel = useObservable(ValueContainer.currentLevelObservable);
    const characterImageBase64 = useObservable(
        ValueContainer.characterImageBase64Observable
    );

    const [backgroundImage, setBackgroundImage] = useState('');

    useEffect(() => {
        const img = new Image();
        img.src = imageUri || `data:image/png;base64,${characterImageBase64}`;
        img.onload = () => setBackgroundImage(`url(${img.src})`);
        img.onerror = () =>
            setBackgroundImage(`url(data:image/png;base64,${characterImageBase64})`);
    }, [imageUri, characterImageBase64]);


    return (
        <div
            className={`${styles.profileImage} ${styles.zoom}`}
            style={{
                backgroundImage: backgroundImage,
                width: size,
                height: size,
             
            }}
        >
 
            <img
                src={Images.Profile.ProfileOverlay}
                alt="User avatar"
                className={styles.overlay}                  
            />
        
            {showLevel && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        color: levelTextColor,
                        fontSize: size / 3,
                        height: size / 3,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textShadow: '0 0 5px #000',
                        transform: 'translateY(20%)',
                    }}
                >
                    {currentLevel ?? 1}
                </div>
            )}
            <div className={styles.backdrop}></div>
        </div>
    );
};

export default UserAvatar;
