import AcquiredOffer from './acquired_offer';
import StandardHttpAPI from '../standard_http_api';
import { constants } from '../../utils/constants';
import ValueContainer from '../../utils/value_container';
import CryptoCurrencyInfo from './crypto_currency_info';
import ClaimedOffer from './claimed_offers';
import Vault from './vault';


const API_URL = constants.API_URL;

class OfferApi {
    static async getAcquiredOffers(): Promise<AcquiredOffer[] | undefined> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}bubble/acquiredOffers`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            if (response.responseCode === 404) {
                return [];
            }

            return undefined;
        }

        return response.response.map((acquiredOffer: any) =>
            AcquiredOffer.fromJsonLowerCamelCase(acquiredOffer)
        );
    }

    static async getVault(): Promise<Vault | undefined> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}bubble/vault`,
            bearer: ValueContainer.token,
        });

        return Vault.fromJsonLowerCamelCase(response.response);
    }

    static async claim(cryptoCurrency: string): Promise<ClaimedOffer | undefined> {
        const response = await StandardHttpAPI.post({
            uri: `${API_URL}bubble/acquiredoffers/claim`,
            bearer: ValueContainer.token,
            body: {
                cryptoCurrency: cryptoCurrency,
            },
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return ClaimedOffer.fromJsonLowerCamelCase(response.response);
    }
}

export default OfferApi;
