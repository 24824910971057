import ReactGA from 'react-ga4';

const isProduction = process.env.NODE_ENV === 'production';

export const initializeAnalytics = () => {
  if (isProduction) {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID || ''); // Replace with your Measurement ID
    ReactGA.send('pageview');
  }
};

export const logEvent = (category: string, action: string, label?: string) => {
  if (isProduction) {
    ReactGA.event({
      category,
      action,
      label,
    });
  }
};

export const logPageView = (path: string) => {
  if (isProduction) {
    ReactGA.send({ hitType: 'pageview', page: path });
  }
};