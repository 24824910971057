import { TimeDuration } from 'typed-duration';
import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import AcquiredOffer from '../../../apis/offer_api/acquired_offer';
import OfferApi from '../../../apis/offer_api/offer_api';
import AuthenticationComponent from '../authentication/authentication_component';
import ComponentErrorType from '../../component_container/enums/component_error_type';
import SignalRComponent from '../signalr/signalr_component';
import ContainerHelper from '../../component_container/utilities/container_helper';
import SignalRConnected from '../signalr/signalr_connected';
import CompactJsonOffer from '../../../apis/offer_api/compact_json_offer';
import BubbleApi from '../../../apis/bubble_api/bubble_api';
import StandardHttpResponse from '../../../apis/models/standard_http_response';
import GroupedOffer from '../../../apis/offer_api/grouped_offer';
import MapBubble from '../../../apis/models/map_bubble';
import OverlayComponent from '../overlay/overlay_component';
import { toast } from 'react-toastify';
import CryptoCurrencyInfo from '../../../apis/offer_api/crypto_currency_info';
import ClaimedOffer from '../../../apis/offer_api/claimed_offers';
import formatNumber from '../../../utils/currency_formatter';

type OfferSubscriber = () => void;

class OfferComponent extends Component {
    private _subscribers: OfferSubscriber[] = [];

    private _overlayComponent: OverlayComponent | undefined = undefined;
    private _focusedBubble: MapBubble | undefined = undefined;

    private _newAcquisition: AcquiredOffer | undefined = undefined;
    private _newAcquisitionSeenDate: Date | undefined = undefined;

    get newAcquisition(): AcquiredOffer | undefined {
        return this._newAcquisition;
    }

    markNewAcquisitionAsSeen(): void {
        if (this._newAcquisition) {
            this._newAcquisitionSeenDate = new Date();
        }
    }

    get focusedBubble(): MapBubble | undefined {
        return this._focusedBubble;
    }

    private _mineableOffers: CompactJsonOffer[] = [];
    get mineableOffers(): CompactJsonOffer[] {
        return this._mineableOffers;
    }

    private _minedOffer: AcquiredOffer | undefined = undefined;
    get minedOffer(): AcquiredOffer | undefined {
        return this._minedOffer;
    }

    registerSubscriber(subscriber: OfferSubscriber): void {
        this._subscribers.push(subscriber);
    }

    unregisterSubscriber(subscriber: OfferSubscriber): void {
        this._subscribers = this._subscribers.filter((s) => s !== subscriber);
    }

    private _notifySubscribers(): void {
        this._subscribers.forEach((subscriber) => subscriber());
        this._notifyObservers(undefined);
    }

    private _acquiredOffers: CryptoCurrencyInfo[] | undefined = undefined;

    get acquiredOffers(): CryptoCurrencyInfo[] {
        return this._acquiredOffers || [];
    }

    private _claims: ClaimedOffer[] = [];

    get claims(): ClaimedOffer[] {
        return this._claims;
    }



    // get groupedOffers():  GroupedOffer[]  {
    //     const grouped = this.acquiredOffers.reduce((acc, offer) => {
    //         const existing = acc.get(offer.cryptoCurrency??'');
    //         if (existing) {
    //           existing.totalCryptoCurrencyAmount += offer.cryptoCurrencyAmount??0;
    //           existing.offers.push(offer);
    //         } else {
    //           acc.set(offer.cryptoCurrency??'', {
    //             cryptoCurrency: offer.cryptoCurrency,
    //             offerName: offer.offerTitle,
    //             totalCryptoCurrencyAmount: offer.cryptoCurrencyAmount??0,
    //             offerDescription: offer.description,
    //             offers: [offer],
    //           });
    //         }
    //         return acc;
    //       }, new Map<string, GroupedOffer>());
    //
    //       return Array.from(grouped.values());
    // }

    private _fetching: boolean = false;
    get fetching(): boolean {
        return this._fetching;
    }

    async markOfferAsSeen(uniqueOfferId: string): Promise<void> {
        // TODO: Implement
    }

    private async _initValues(): Promise<void> {
        const vault = await OfferApi.getVault();

        if (!vault) {
            return Promise.reject();
        }

        this._acquiredOffers = vault.cryptoCurrencies;
        this._claims = vault.claimedOffers;
    }

    get type(): Function {
        return OfferComponent;
    }

    get name(): string {
        return 'Offer Component';
    }

    async load(): Promise<ComponentError[]> {
        await this.setDependencyLocked([AuthenticationComponent]);

        const initSuccess = await this._initValues()
            .then(() => true)
            .catch(() => false);

        if (!initSuccess) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'offerComponentFailedToLoad'.tr()
                ),
            ];
        }

        await this.setDependencyLocked([SignalRComponent]);

        const signalRComponent = await ContainerHelper.getSignalRComponent();

        signalRComponent.registerMethodHandler(
            'OfferAcquired',
            this._offerAcquiredMethodHandler.bind(this)
        );

        signalRComponent.registerMethodHandler(
            'RemoveAcquiredOffer',
            this._removeAcquiredOfferMethodHandler.bind(this)
        );

        signalRComponent.registerMethodHandler(
            'OfferRedeemed',
            this._offerRedeemedMethodHandler.bind(this)
        );

        signalRComponent.addConnectionStateChangedEventHandler(
            this._connectionStateChangedEventHandler.bind(this)
        );

        ContainerHelper.getOverlayComponent().then((overlayComponent) => {
            this._overlayComponent = overlayComponent;
            overlayComponent.addSubscriber(
                this._overlayComponentSubscriber.bind(this)
            );
        });

        return [];
    }

    private _offerAcquiredMethodHandler(...args: any[]): void {
        const jsonStr = args[0];
        const json = JSON.parse(jsonStr);
        const acquiredOffer = AcquiredOffer.fromJson(json);

        // this._acquiredOffers!.push(acquiredOffer);

        const cryptoCurrencyInfo = this._acquiredOffers?.find(
            (offer) => offer.cryptoCurrency === acquiredOffer.cryptoCurrency
        );

        if (!cryptoCurrencyInfo) {
            throw new Error('Crypto currency info not found');
        }

        if (cryptoCurrencyInfo.cryptoCurrencyAmount) {
            cryptoCurrencyInfo.cryptoCurrencyAmount +=
                acquiredOffer.cryptoCurrencyAmount || 0;
        } else {
            cryptoCurrencyInfo.cryptoCurrencyAmount =
                acquiredOffer.cryptoCurrencyAmount || 0;
        }

        this._newAcquisition = acquiredOffer;
        this._newAcquisitionSeenDate = undefined;

        this._notifySubscribers();
    }

    private _removeAcquiredOfferMethodHandler(...args: any[]): void {
        const uniqueId = args[0];
        // TODO: re-implement this but pass the amount of the offer that was removed (for claim)

        // this._acquiredOffers = this._acquiredOffers?.filter(
        //     (offer) => offer.uniqueId !== uniqueId
        // );

        this._notifySubscribers();
    }

    private _offerRedeemedMethodHandler(...args: any[]): void {
        const uniqueId = args[0];

        // const acquiredOffer = this._acquiredOffers?.find(
        //     (offer) => offer.uniqueId === uniqueId
        // );
        //
        // if (acquiredOffer) {
        //     acquiredOffer.isUsed = true;
        // }

        this._notifySubscribers();
    }

    private async _connectionStateChangedEventHandler(state: SignalRConnected) {
        if (state === SignalRConnected.Connected) {
            this.fetch();
        }
    }

    private _overlayComponentSubscriber(): void {
        if (this._focusedBubble !== this._overlayComponent!.focusedBubble) {
            this._focusedBubble = this._overlayComponent!.focusedBubble;
            this._onFocusedBubbleChanged();
        }
    }

    private _onFocusedBubbleChanged(): void {
        console.error(this._focusedBubble);
        if (this._focusedBubble) {
            console.error(this._focusedBubble);
            this.getOffersForBubble(this._focusedBubble.id)
                .then((offers) => {
                    if (offers.length > 0) {
                        this.purchaseOffer(offers[0].offerId);
                    }
                    // this._notifySubscribers();
                    console.error(offers);
                })
                .catch((e) => {
                    console.error(e);
                });
        } else {
            this._mineableOffers = [];
            this._notifySubscribers();
        }
    }

    async fetch(): Promise<void> {
        if (this._fetching) {
            return;
        }
        let fetched = false;
        this._fetching = true;

        this._notifySubscribers();

        while (!fetched) {
            fetched = await this._initValues()
                .then(() => true)
                .catch(() => false);

            if (!fetched) {
                await new Promise((resolve) => setTimeout(resolve, 2000));
            } else {
                this._fetching = false;
                this._notifySubscribers();
            }
        }

        this._fetching = false;
    }

    async onUnload(): Promise<void> {}
    async onPause(): Promise<void> {}
    async onResume(): Promise<void> {}
    update(sinceLastUpdate: TimeDuration): void {
        if (this._newAcquisition && this._newAcquisitionSeenDate) {
            // If the new acquisition has been seen for 5 seconds, clear it
            const now = new Date();
            if (now.getTime() - this._newAcquisitionSeenDate.getTime() > 5000) {
                this._newAcquisition = undefined;
                this._newAcquisitionSeenDate = undefined;
                this._notifySubscribers();
            }
        }
    }

    async getOffersForBubble(
        bubbleId: number | string
    ): Promise<CompactJsonOffer[]> {
        return await BubbleApi.getOffersForBubble(bubbleId);
    }

    async claim(cryptoCurrency: string): Promise<boolean> {     
        try {
            const claimedOffer = await OfferApi.claim(cryptoCurrency);
            this._claims.push(claimedOffer as ClaimedOffer);

            this._acquiredOffers = this._acquiredOffers?.map((offer) => {
                if (offer.cryptoCurrency === claimedOffer?.cryptoCurrency) {
                    offer.cryptoCurrencyAmount = 0;
                }
                return offer;
            });
            this._notifySubscribers();
            toast.success(`Your claim of ${formatNumber(claimedOffer?.cryptoCurrencyAmount)} ${claimedOffer?.cryptoCurrency} has been submitted`);    
            return true;
        } catch (error: any) {
            var errorData = error as StandardHttpResponse;
            if (errorData && errorData.hasErrorMessage) {
                toast.error(errorData.errorMessage);
            }
            return false;
        }
    }

    async purchaseOffer(
        offerId: string
    ): Promise<[string?, string?, AcquiredOffer?]> {
        try {
            const acquiredOffer = await BubbleApi.purchaseOffer(offerId);
            this._mineableOffers = [];
            this._minedOffer = acquiredOffer;

            this._notifySubscribers();
            return [undefined, undefined, acquiredOffer];
        } catch (e) {
            const response = e as StandardHttpResponse;

            if (!response) {
                throw e;
            }

            if (response.isInternetError) {
                return [
                    'noInternetConnection'.tr(),
                    'noInternetConnectionMessage'.tr(),
                    undefined,
                ];
            }

            return [response.error, response.errorMessage, undefined];
        }
    }
}

export default OfferComponent;
