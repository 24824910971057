import { constants } from "../../utils/constants";
import ValueContainer from "../../utils/value_container";
import Wallet from "../models/profile/wallet";
import StandardHttpAPI from "../standard_http_api";

const API_URL = constants.API_URL;

class ProfileApi {

  static async updateWallet(walletType: string, walletAddress: string): Promise<Wallet> {
        const response = await StandardHttpAPI.post({
            uri: `${API_URL}profiles/wallet`,
            bearer: ValueContainer.token,
            body: {
                WalletType: walletType,
                WalletAddress: walletAddress,
            },
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return Wallet.fromJsonLowerCamelCase(response.response);
    }
}

export default ProfileApi;