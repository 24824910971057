class TelegramValidationResult {
    isValid: boolean;
    message?: string;
    token?: string;
    isNewUser?: boolean;

    constructor({
        isValid,
        message,
        token,
        isNewUser,
    }: {
        isValid: boolean;
        message?: string;
        token?: string;
        isNewUser?: boolean;
    }) {
        this.isValid = isValid;
        this.message = message;
        this.token = token;
        this.isNewUser = isNewUser;
    }
}

export default TelegramValidationResult;
