import background from '../../../assets/images/backgrounds/background.png';
import blueTile from '../../../assets/images/backgrounds/blue_tile.png';
import buttonGreenSmall from '../../../assets/images/backgrounds/button_green_small.png';
import buttonOrangeBig from '../../../assets/images/backgrounds/button_orange_big.png';
import animatedBackground from '../../../assets/images/backgrounds/background-ani.png';
import largeButton from '../../../assets/images/backgrounds/large-btn.png';
import locationRequiredHero from '../../../assets/images/backgrounds/location_required_hero.jpg';

import arrowBackIcon from '../../../assets/images/icons/arrow_back.png';
import closeIcon from '../../../assets/images/icons/exit_icon.png';
import exitIcon from '../../../assets/images/icons/exit.png';
import greenSmallPlus from '../../../assets/images/icons/button_green_small_plus.png';
import checkmark from '../../../assets/images/icons/checkmark.png';
import coinIcon from '../../../assets/images/icons/coin.png';
import diggersGoCoin from '../../../assets/images/icons/diggers_go_coin.png';
import locationIcon from '../../../assets/images/icons/pin-icon.png';
import star from '../../../assets/images/icons/star.png';

import coinStackIcon from '../../../assets/images/navigation_tab/btn-vault.png';
import coinStacksIcon from '../../../assets/images/navigation_tab/coin_stacks_icon.png';
import hammerIcon from '../../../assets/images/navigation_tab/btn-play.png';
import heartIcon from '../../../assets/images/navigation_tab/btn-social.png';
import navigationBarBackground from '../../../assets/images/navigation_tab/menu-bg.png';
import notebookIcon from '../../../assets/images/navigation_tab/btn-quests.png';
import presentIcon from '../../../assets/images/navigation_tab/present_icon.png';
import tabActive from '../../../assets/images/navigation_tab/tab_active.png';
import tabInactive from '../../../assets/images/navigation_tab/tab_inactive.png';

import blueHexagon from '../../../assets/images/panels/blue_hexagon.png';
import blueTransparentRibbon from '../../../assets/images/panels/blue_transparent_ribbon.png';
import frame from '../../../assets/images/panels/frame.png';
import ribbonVioletBig from '../../../assets/images/panels/ribbon_violet_big.png';
import topPanelCounter from '../../../assets/images/panels/top_panel_counter.png';
import mineBanner from '../../../assets/images/panels/mine-banner.png';

import logoNoBg from '../../../assets/images/logos/logo_no_bg.png';

import coinsIcon from '../../../assets/images/icons/coin-icon.png';
import loader from '../../../assets/images/icons/loader.svg';
import iconRef from '../../../assets/images/icons/icon-ref.png';
import inviteBanner from '../../../assets/images/panels/invite-banner.png';
import referralBanner from '../../../assets/images/panels/referral-banner.png';

import achievmentbanner from '../../../assets/images/profile/achievement-banner.png';
import backButton from '../../../assets/images/profile/back-btn.png';
import ercWallet from '../../../assets/images/profile/erc-wallet.png';
import ercWalletConnect from '../../../assets/images/profile/erc-wallet-connected.png';
import evmWallet from '../../../assets/images/profile/evm-wallet.png';
import evmWalletConnect from '../../../assets/images/profile/evm-wallet-connected.png';
import solanaWallet from '../../../assets/images/profile/solana-wallet.png';
import solanaWalletConnect from '../../../assets/images/profile/solana-wallet-connected.png';
import tonWallet from '../../../assets/images/profile/ton-wallet.png';
import tonWalletConnect from '../../../assets/images/profile/ton-wallet-connected.png';
import profileOverlay from '../../../assets/images/profile/profile-overlay.png';
import exampleProfile from '../../../assets/images/profile/example-profile.png';

// Stone states
import stoneGreen0 from '../../../assets/images/stone_states/common/stone_green_0.png';
import stoneGreen1 from '../../../assets/images/stone_states/common/stone_green_1.png';
import stoneGreen2 from '../../../assets/images/stone_states/common/stone_green_2.png';
import stoneGreen3 from '../../../assets/images/stone_states/common/stone_green_3.png';
import stoneGreen4 from '../../../assets/images/stone_states/common/stone_green_4.png';
import stoneGreen5 from '../../../assets/images/stone_states/common/stone_green_5.png';
import stoneGreen6 from '../../../assets/images/stone_states/common/stone_green_6.png';

import stoneBlue0 from '../../../assets/images/stone_states/rare/stone_blue_0.png';
import stoneBlue1 from '../../../assets/images/stone_states/rare/stone_blue_1.png';
import stoneBlue2 from '../../../assets/images/stone_states/rare/stone_blue_2.png';
import stoneBlue3 from '../../../assets/images/stone_states/rare/stone_blue_3.png';
import stoneBlue4 from '../../../assets/images/stone_states/rare/stone_blue_4.png';
import stoneBlue5 from '../../../assets/images/stone_states/rare/stone_blue_5.png';
import stoneBlue6 from '../../../assets/images/stone_states/rare/stone_blue_6.png';

import stonePurple0 from '../../../assets/images/stone_states/epic/stone_purple_0.png';
import stonePurple1 from '../../../assets/images/stone_states/epic/stone_purple_1.png';
import stonePurple2 from '../../../assets/images/stone_states/epic/stone_purple_2.png';
import stonePurple3 from '../../../assets/images/stone_states/epic/stone_purple_3.png';
import stonePurple4 from '../../../assets/images/stone_states/epic/stone_purple_4.png';
import stonePurple5 from '../../../assets/images/stone_states/epic/stone_purple_5.png';
import stonePurple6 from '../../../assets/images/stone_states/epic/stone_purple_6.png';

import stoneGold0 from '../../../assets/images/stone_states/legendary/stone_gold_0.png';
import stoneGold1 from '../../../assets/images/stone_states/legendary/stone_gold_1.png';
import stoneGold2 from '../../../assets/images/stone_states/legendary/stone_gold_2.png';
import stoneGold3 from '../../../assets/images/stone_states/legendary/stone_gold_3.png';
import stoneGold4 from '../../../assets/images/stone_states/legendary/stone_gold_4.png';
import stoneGold5 from '../../../assets/images/stone_states/legendary/stone_gold_5.png';
import stoneGold6 from '../../../assets/images/stone_states/legendary/stone_gold_6.png';

import stoneBroken from '../../../assets/images/stone_states/stone_broken.png';

import xIcon from '../../../assets/images/icons/x-icon.jpg';
import discordIcon from '../../../assets/images/icons/discord.svg';
import tiktokIkon from '../../../assets/images/icons/tiktok-logo.jpg';
import telegramIcon from '../../../assets/images/icons/telegram.png';

import challengeLogo from '../../../assets/images/partner/challenge-logo.png';
import challengeBanner from '../../../assets/images/partner/challenge-partner-banner.jpg';
import brettLogo from '../../../assets/images/partner/brett-logo.png';
import brettBanner from '../../../assets/images/partner/brett-partner-banner.jpg';

const Images = {
    Backgrounds: {
        Background: background,
        BlueTile: blueTile,
        ButtonGreenSmall: buttonGreenSmall,
        ButtonOrangeBig: buttonOrangeBig,
        AnimatedBackground: animatedBackground,
        LargeButton: largeButton,
        LocationRequiredHero: locationRequiredHero,
    },
    Icons: {
        ArrowBack: arrowBackIcon,
        Close: closeIcon,
        Exit: exitIcon,
        GreenSmallPlus: greenSmallPlus,
        Checkmark: checkmark,
        Coin: coinIcon,
        Location: locationIcon,
        Star: star,
        DiggersGoCoin: diggersGoCoin,
        Coins: coinsIcon,
        Loader: loader,
        IconRef: iconRef,
        DiscordIcon: discordIcon,
        XIcon: xIcon,
        TiktokIcon: tiktokIkon,
        TelegramIcon: telegramIcon,
    },
    NavigationTab: {
        CoinStack: coinStackIcon,
        CoinStacks: coinStacksIcon,
        Hammer: hammerIcon,
        Heart: heartIcon,
        NavigationBarBackground: navigationBarBackground,
        Notebook: notebookIcon,
        Present: presentIcon,
        TabActive: tabActive,
        TabInactive: tabInactive,
    },
    Panels: {
        BlueHexagon: blueHexagon,
        BlueTransparentRibbon: blueTransparentRibbon,
        Frame: frame,
        RibbonVioletBig: ribbonVioletBig,
        TopPanelCounter: topPanelCounter,
        MineBanner: mineBanner,
        InviteBanner: inviteBanner,
        ReferralBanner: referralBanner,
    },
    Logos: {
        logoNoBg: logoNoBg,
    },
    Profile: {
        AchievementBanner: achievmentbanner,
        BackButton: backButton,
        ErcWallet: ercWallet,
        ErcWalletConnect: ercWalletConnect,
        EvmWallet: evmWallet,
        EvmWalletConnect: evmWalletConnect,
        SolanaWallet: solanaWallet,
        SolanaWalletConnect: solanaWalletConnect,
        TonWallet: tonWallet,
        TonWalletConnect: tonWalletConnect,
        ProfileOverlay: profileOverlay,
        ExampleProfile: exampleProfile,
    },
    StoneStates: {
        Common: {
            stage_0: stoneGreen0,
            stage_1: stoneGreen1,
            stage_2: stoneGreen2,
            stage_3: stoneGreen3,
            stage_4: stoneGreen4,
            stage_5: stoneGreen5,
            stage_6: stoneGreen6,
            stage_7: stoneBroken,
        },
        Rare: {
            stage_0: stoneBlue0,
            stage_1: stoneBlue1,
            stage_2: stoneBlue2,
            stage_3: stoneBlue3,
            stage_4: stoneBlue4,
            stage_5: stoneBlue5,
            stage_6: stoneBlue6,
            stage_7: stoneBroken,
        },
        Epic: {
            stage_0: stonePurple0,
            stage_1: stonePurple1,
            stage_2: stonePurple2,
            stage_3: stonePurple3,
            stage_4: stonePurple4,
            stage_5: stonePurple5,
            stage_6: stonePurple6,
            stage_7: stoneBroken,
        },
        Legendary: {
            stage_0: stoneGold0,
            stage_1: stoneGold1,
            stage_2: stoneGold2,
            stage_3: stoneGold3,
            stage_4: stoneGold4,
            stage_5: stoneGold5,
            stage_6: stoneGold6,
            stage_7: stoneBroken,
        },
        stoneBroken: stoneBroken,
    },
    Partners:{
        ChallengeLogo: challengeLogo,
        ChallengeBanner: challengeBanner,
        BrettLogo: brettLogo,
        BrettBanner: brettBanner,
    }
};

export default Images;
