import React, { useCallback } from 'react';
import styles from './mini_profile.module.css';
import Images from '../../../component_system/components/preload/images';
import useObservable from '../../../utils/observable/use_observable_hook';
import ValueContainer from '../../../utils/value_container';
import LevelProgressBar from '../level_progress_bar';

interface MiniProfileProps {
   onProfileAction: () => void;
   onCoinsAction: () => void;
   onRef?: (ref: HTMLDivElement | null) => void;
   imageUri?: string;
  }

const MiniProfile: React.FC<MiniProfileProps> = ({onProfileAction,onCoinsAction,onRef,imageUri}) => {

    const currentLevel = useObservable(ValueContainer.currentLevelObservable);
    const username = useObservable(ValueContainer.usernameObservable);
    const coins = useObservable(ValueContainer.coinsObservable);
    const currentLevelExperience = useObservable(
        ValueContainer.currentLevelExperienceObservable
    );
    const experienceUntilNextLevel = useObservable(
        ValueContainer.experienceUntilNextLevelObservable
    );
    const experienceGained =
    (currentLevelExperience ?? 1) - (experienceUntilNextLevel ?? 1);

    const characterImageBase64 = useObservable(
        ValueContainer.characterImageBase64Observable
    );

    const addDefaultSrc = (event: any) => {
        event.target.src = `data:image/png;base64,${characterImageBase64}`;
    };

    const formatCurrency = useCallback((value: number) => {
        return value
            .toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })
            .replace('$', '')
            .split('.')[0];
    }, []);

  return (
    <>
    <div className={styles.miniprofile} ref={(ref) => onRef?.(ref)}>
      <div onClick={onProfileAction} className={styles.profileImg} style={{backgroundImage: `url(${imageUri || `data:image/png;base64,${characterImageBase64}`})`}}>
        <div className={styles.level}>{currentLevel ?? 1}</div>
        <img src={Images.Profile.ProfileOverlay} className={styles.overlay} alt="Profile overlay" />
        <div className={styles.backdrop}></div>
      </div>

      <div className={styles.levelHolder}>
        <h3> {username ?? 'Username'}</h3>
        <div className={styles.currency} onClick={onCoinsAction}>
          <img src={Images.Icons.DiggersGoCoin} className={styles.imgIcon} alt=""/>
          <span>{formatCurrency(coins ?? 0)}</span>
        </div>
        <LevelProgressBar
                        width={100}
                        height={15}
                        borderRadius={6} 
        />     
        <div className={styles.levelInfo}>
          <p className="left">{`${experienceGained.toFixed(0)} / ${(currentLevelExperience ?? 1).toFixed(0)} XP`}</p>
        </div>   
      </div>
    </div>
    <div className={styles.topfade}></div>   
    </>
  );
};

export default MiniProfile;