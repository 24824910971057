import React from 'react';
import ReactModal from 'react-modal';
import { confirmable, ConfirmDialog, createConfirmation } from 'react-confirm';

import tutorial001 from '../../../assets/images/tutorial/tutorial_001.jpg';
import tutorial002 from '../../../assets/images/tutorial/tutorial_002.jpg';
import tutorial003 from '../../../assets/images/tutorial/tutorial_003.jpg';
import tutorial004 from '../../../assets/images/tutorial/tutorial_004.jpg';
import tutorial005 from '../../../assets/images/tutorial/tutorial_005.jpg';

import diggersGoText from '../../../assets/images/tutorial/diggers_go_text.png';

const tutorialImages = [
    tutorial001,
    tutorial002,
    tutorial003,
    tutorial004,
    tutorial005,
];

interface TutorialPopupProps {
    show: boolean;
    onAccept: () => void;
}

const TutorialPopup: React.FC<TutorialPopupProps> = ({ show, onAccept }) => {
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

    return (
        <ReactModal
            isOpen={show}
            style={{
                overlay: {
                    zIndex: 3,
                    backgroundColor: 'var(--primary-color)',
                    padding: '0',
                    margin: '0',
                    backgroundImage: `url(${tutorialImages[currentImageIndex]})`,
                    backgroundSize: 'auto 100vh',
                    backgroundPosition: 'bottom',
                    inset: '0',
                },
                content: {
                    border: '0px',
                    backgroundColor: 'transparent',
                    // borderRadius: '8px',
                    padding: '0px',
                    // maxWidth: '400px',
                    margin: '0',
                    height: '100vh',
                    width: '100vw',
                    inset: '0',
                    fontFamily: 'Luckiest Guy',
                },
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100vw',
                    height: '100vh',
                    textShadow:
                        '-2.5px 0 black, 0 2.5px black, 2.5px 0 black, 0 -2.5px black, -2.5px 2.5px black, 2.5px 2.5px black, 2.5px -2.5px black, -2.5px -2.5px black',
                }}
                onClick={() => {
                    if (currentImageIndex < tutorialImages.length - 1) {
                        setCurrentImageIndex(currentImageIndex + 1);
                    } else {
                        onAccept();
                    }
                }}
            >
                {currentImageIndex === 0 && (
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '0',
                            left: '50%',
                            transform: 'translate(-50%, 0)',
                            textAlign: 'center',
                            width: '85%',
                        }}
                    >
                        <h1
                            style={{
                                color: 'var(--white-color)',
                                fontSize: '24px',
                                marginBottom: '0',
                            }}
                        >
                            {'tutorialImage1Text1'.tr()}
                        </h1>
                        <img
                            src={diggersGoText}
                            alt="Diggers Go"
                            style={{
                                height: '50px',
                                width: 'auto',
                                margin: '0',
                            }}
                        />
                        <p
                            style={{
                                color: 'var(--white-color)',
                                fontSize: '24px',
                                lineHeight: '1.2',
                                marginBottom: '15vh',
                                marginTop: '0',
                            }}
                        >
                            {'tutorialImage1Text2'
                                .tr()
                                .split('\n')
                                .map((item, key) => {
                                    return (
                                        <span key={key}>
                                            {item}
                                            <br />
                                        </span>
                                    );
                                })}
                        </p>
                    </div>
                )}
                {currentImageIndex === 1 && (
                    <>
                        <div
                            style={{
                                position: 'absolute',
                                top: '0',
                                left: '50%',
                                transform: 'translate(-50%, 0)',
                                textAlign: 'center',
                                width: '85%',
                            }}
                        >
                            <h1
                                style={{
                                    color: 'var(--white-color)',
                                    fontSize: '38px',
                                    marginTop: '4vh',
                                }}
                            >
                                {'tutorialImage2Text1'.tr()}
                            </h1>
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                bottom: '0',
                                left: '50%',
                                transform: 'translate(-50%, 0)',
                                textAlign: 'center',
                                width: '85%',
                            }}
                        >
                            <p
                                style={{
                                    color: 'var(--white-color)',
                                    fontSize: '24px',
                                    lineHeight: '1.2',
                                    marginBottom: '15vh',
                                    marginTop: '0',
                                }}
                            >
                                {'tutorialImage2Text2'
                                    .tr()
                                    .split('\n')
                                    .map((item, key) => {
                                        return (
                                            <span key={key}>
                                                {item}
                                                <br />
                                            </span>
                                        );
                                    })}
                            </p>
                        </div>
                    </>
                )}
                {currentImageIndex === 2 && (
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '0',
                            left: '50%',
                            transform: 'translate(-50%, 0)',
                            textAlign: 'center',
                            width: '85%',
                        }}
                    >
                        <p
                            style={{
                                color: 'var(--white-color)',
                                fontSize: '24px',
                                lineHeight: '1.2',
                                marginBottom: '28vh',
                                marginTop: '0',
                            }}
                        >
                            {'tutorialImage3Text1'
                                .tr()
                                .split('\n')
                                .map((item, key) => {
                                    return (
                                        <span key={key}>
                                            {item}
                                            <br />
                                        </span>
                                    );
                                })}
                        </p>
                    </div>
                )}
                {currentImageIndex === 3 && (
                    <>
                        <div
                            style={{
                                position: 'absolute',
                                top: '0',
                                left: '50%',
                                transform: 'translate(-50%, 0)',
                                textAlign: 'center',
                                width: '85%',
                            }}
                        >
                            <p
                                style={{
                                    color: 'var(--white-color)',
                                    fontSize: '24px',
                                    lineHeight: '1.2',
                                    marginBottom: '0',
                                    marginTop: '6vh',
                                }}
                            >
                                {'tutorialImage4Text1'
                                    .tr()
                                    .split('\n')
                                    .map((item, key) => {
                                        return (
                                            <span key={key}>
                                                {item}
                                                <br />
                                            </span>
                                        );
                                    })}
                            </p>
                            <h1
                                style={{
                                    color: 'var(--white-color)',
                                    fontSize: '55px',
                                    marginTop: '0',
                                }}
                            >
                                {'tutorialImage4Text2'.tr()}
                            </h1>
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                bottom: '0',
                                left: '50%',
                                transform: 'translate(-50%, 0)',
                                textAlign: 'center',
                                width: '85%',
                            }}
                        >
                            <p
                                style={{
                                    color: 'var(--white-color)',
                                    fontSize: '24px',
                                    lineHeight: '1.2',
                                    marginBottom: '20vh',
                                    marginTop: '0',
                                }}
                            >
                                {'tutorialImage4Text3'
                                    .tr()
                                    .split('\n')
                                    .map((item, key) => {
                                        return (
                                            <span key={key}>
                                                {item}
                                                <br />
                                            </span>
                                        );
                                    })}
                            </p>
                        </div>
                    </>
                )}
                {currentImageIndex === 4 && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '50%',
                            transform: 'translate(-50%, 0)',
                            textAlign: 'center',
                            width: '85%',
                        }}
                    >
                        <p
                            style={{
                                color: 'var(--white-color)',
                                fontSize: '24px',
                                lineHeight: '1.2',
                                marginBottom: '0',
                                marginTop: '16vh',
                            }}
                        >
                            {'tutorialImage5Text1'
                                .tr()
                                .split('\n')
                                .map((item, key) => {
                                    return (
                                        <span key={key}>
                                            {item}
                                            <br />
                                        </span>
                                    );
                                })}
                        </p>
                    </div>
                )}
            </div>
        </ReactModal>
    );
};

type TutorialDialogProps = {
    options: {
        onConfirm?: () => boolean;
    };
};

export const TutorialDialog: ConfirmDialog<TutorialDialogProps, boolean> = ({
    show,
    proceed,
    options: { onConfirm = undefined },
}) => {
    return (
        <TutorialPopup
            show={show}
            onAccept={() => {
                if (onConfirm) {
                    if (onConfirm()) {
                        proceed(true);
                    }
                }
                proceed(true);
                return true;
            }}
        />
    );
};

const tutorialDialog = confirmable(TutorialDialog);

export const confirmTutorialDialog = createConfirmation(tutorialDialog);
