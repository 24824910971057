import React, { useState, useEffect, useCallback, useRef } from 'react';
import styles from './custom_bottom_navigation_bar.module.css';
import hammerIcon from '../../assets/images/navigation_tab/btn-play.png';
import coinStackIcon from '../../assets/images/navigation_tab/btn-vault.png';
import heartIcon from '../../assets/images/navigation_tab/btn-social.png';
import notebookIcon from '../../assets/images/navigation_tab/btn-quests.png';

import ContainerHelper from '../../component_system/component_container/utilities/container_helper';
import useFetchComponent from '../../component_system/component_container/utilities/use_fetch_hook';

const CustomBottomNavigationBar: React.FC<any> = () => {
    const [height, setHeight] = useState(0);
    const prevHeightRef = useRef(0);
    const ref = useRef<HTMLDivElement>(null);

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [visible, setVisible] = useState(false);

    const navigationComponent = useFetchComponent(
        ContainerHelper.getNavigationComponent
    );

    const friendComponent = useFetchComponent(
        ContainerHelper.getFriendComponent
    );

    const offerComponent = useFetchComponent(
        ContainerHelper.getOffersComponent
    );

    useEffect(() => {
        if (ref.current) {
            const currentHeight = ref.current.clientHeight;
            // Only update height if it has changed
            if (currentHeight !== prevHeightRef.current) {
                setHeight(currentHeight);
                prevHeightRef.current = currentHeight; // Update the previous height
            }
        }
    });

    useEffect(() => {
        if (height > 0) {
            const navComponent = ContainerHelper.forceGetNavigationComponent();
            navComponent.navigationBarHeight = height;
        }
    }, [height]);

    const navigationComponentSubscriber = useCallback(() => {
        if (navigationComponent) {
            setSelectedIndex(navigationComponent.selectedIndex);
            setVisible(navigationComponent.visible);
        }
    }, [navigationComponent]);

    useEffect(() => {
        if (navigationComponent) {
            navigationComponent.addSubscriber(navigationComponentSubscriber);
        }

        return () => {
            if (navigationComponent) {
                navigationComponent.removeSubscriber(
                    navigationComponentSubscriber
                );
            }
        };
    }, [navigationComponent]);

    const handleNavigateToIndex = useCallback(
        (index: number) => {
            if (navigationComponent) {
                navigationComponent.navigateToIndex(index);
            }
        },
        [navigationComponent]
    );

    return (
        <div
            className={styles.menu}
            ref={ref}
            style={{
                zIndex: visible ? 2 : -1,
            }}
        >
            {/* Icon 1: Play */}
            <NavItem
                index={0}
                iconImage={hammerIcon}
                label="Play"
                isSelected={selectedIndex === 0}
                onNavigate={() => handleNavigateToIndex(0)}
            />

            {/* Icon 2: Rewards */}
            <NavItem
                index={1}
                iconImage={coinStackIcon}
                label="Vault"
                isSelected={selectedIndex === 1}
                onNavigate={() => handleNavigateToIndex(1)}
                badgeCount={offerComponent?.newAcquisition ? '!' : undefined}
            />

            {/* Icon 3: Friends */}
            <NavItem
                index={2}
                iconImage={heartIcon}
                label="Social"
                isSelected={selectedIndex === 2}
                onNavigate={() => handleNavigateToIndex(2)}
                badgeCount={friendComponent?.incomingFriendRequests?.length}
            />

            {/* Icon 4: Quests */}
            <NavItem
                index={3}
                iconImage={notebookIcon}
                label="Quests"
                isSelected={selectedIndex === 3}
                onNavigate={() => handleNavigateToIndex(3)}
            />
        </div>
    );
};

interface NavItemProps {
    index: number;
    iconImage: string;
    label: string;
    isSelected: boolean;
    onNavigate: () => void;
    badgeCount?: number | string;
}

const NavItem: React.FC<NavItemProps> = ({
    iconImage,
    label,
    isSelected,
    onNavigate,
    badgeCount,
}) => {
    const showBadge = (badgeCount: number | string | undefined) => {
        return (
            !!badgeCount && (typeof badgeCount === 'string' || badgeCount > 0)
        );
    };
    return (
        <div
            className={`${styles.menuItem} ${isSelected ? styles.active : ''}`}
            onClick={onNavigate}
        >
            <img src={iconImage} alt={label} className={styles.menuIcon} />
            {showBadge(badgeCount) && (
                <span className={styles.badge}>{badgeCount}</span>
            )}
            <p>{label}</p>
        </div>
    );
};

export default CustomBottomNavigationBar;
