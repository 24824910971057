class TaskCompletion {
    taskId: string;
    status: string;
    completedAt: Date | null;
    pointsEarned: number;
    tokensEarned: number;
    estCompletionAt: Date | null;


    constructor({
        taskId,
        status,
        completedAt,
        pointsEarned,
        tokensEarned,
        estCompletionAt,
    }: {
        taskId: string;     
        status: string;
        completedAt: Date | null;
        pointsEarned: number;
        tokensEarned: number;
        estCompletionAt: Date | null;

    }) {
        this.taskId = taskId;     
        this.status = status;
        this.completedAt = completedAt ? new Date(completedAt) : null;
        this.pointsEarned = pointsEarned;
        this.tokensEarned = tokensEarned;
        this.estCompletionAt = estCompletionAt ? new Date(estCompletionAt) : null;

    }

    static fromJsonLowerCamelCase(json: any): TaskCompletion {
        return new TaskCompletion({
            taskId: json.taskId,
            status: json.status,
            completedAt: json.completedAt ? new Date(json.completedAt) : null,
            pointsEarned: json.pointsEarned,
            tokensEarned: json.tokensEarned,
            estCompletionAt: json.estCompletionAt ? new Date(json.estCompletionAt) : null,
        });
    }

    static fromJsonUpperCase(json: any): TaskCompletion {
        return new TaskCompletion({
            taskId: json.TaskId,         
            status: json.Status,
            completedAt: json.CompletedAt ? new Date(json.CompletedAt) : null,
            pointsEarned: json.PointsEarned,
            tokensEarned: json.TokensEarned,
            estCompletionAt: json.EstCompletionAt ? new Date(json.EstCompletionAt) : null,
        });
    }
}

export default TaskCompletion;