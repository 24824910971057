import { useCallback } from 'react';
import ValueContainer from '../../../utils/value_container';
import styles from './top_panel_counter.module.css';
import topPanelBackground from '../../../assets/images/panels/top_panel_counter.png';
import CurrencyIcon, { CurrencyType } from '../currency_icon';
import useObservable from '../../../utils/observable/use_observable_hook';
import { rarity_text_shadow } from '../../../utils/constants';

type TopPanelCounterProps = {
    onRef?: (ref: HTMLDivElement | null) => void;
    containerClassName?: string;
    onTopPanelClick?: () => void;
};

const TopPanelCounter = ({
    onRef,
    containerClassName,
    onTopPanelClick,
}: TopPanelCounterProps) => {
    const coins = useObservable(ValueContainer.coinsObservable);

    const formatCurrency = useCallback((value: number) => {
        return value
            .toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            })
            .replace('$', '')
            .split('.')[0];
    }, []);

    return (
        <div
            className={containerClassName}
            style={{
                backgroundImage: `url(${topPanelBackground})`,
            }}
            ref={(ref) => onRef?.(ref)}
            onClick={onTopPanelClick}
        >
            <CurrencyIcon type={CurrencyType.Coin} size={24} />
            <span
                className={styles.currency_text}
                style={{
                    marginLeft: 4,
                    marginRight: 4,
                    textShadow: rarity_text_shadow.Legendary,
                }}
            >
                {formatCurrency(coins ?? 0)}
            </span>
        </div>
    );
};

export default TopPanelCounter;
