import React from 'react';
import { rarity_text_shadow } from '../../../utils/constants';

interface MineLockFadeOutProps {
    backgroundColor?: string; // Text and icon color
    textColor?: string; // Text color
    fontSize?: number; // Font size of the amount
    referralsRequired: number;
    rarity?: keyof typeof rarity_text_shadow;
}

const MineLockFadeOut: React.FC<MineLockFadeOutProps> = ({
    backgroundColor = '#F44336',
    // 0xFFF44336
    textColor = 'var(--white-color)',
    fontSize = 12,
    referralsRequired,
    rarity = 'Common',
}) => {
    return (
        <div
            style={{
                padding: '4px',
                borderRadius: '5px',
                backgroundColor: backgroundColor,
                display: 'flex',
                alignItems: 'center',
                color: textColor,
                // textShadow: '0px 3px 11px #00449e63',
                textShadow: rarity_text_shadow[rarity],
            }}
        >
            <p
                style={{
                    margin: '0',
                    fontSize: `${fontSize}px`,
                    fontWeight: 'bold',
                    textAlign: 'center',
                }}
            >
                {`${referralsRequired} MORE`}
                <br />
                {referralsRequired === 1 ? 'REFERRAL' : 'REFERRALS'}
                <br />
                REQUIRED
            </p>
        </div>
    );
};

export default MineLockFadeOut;
