import ClaimedOffer from "./claimed_offers";
import CryptoCurrencyInfo from "./crypto_currency_info";

class Vault {
    cryptoCurrencies: CryptoCurrencyInfo[];
    claimedOffers: ClaimedOffer[];
    constructor({cryptoCurrencies, claimedOffers}: {cryptoCurrencies: CryptoCurrencyInfo[], claimedOffers: ClaimedOffer[]}) {
        this.cryptoCurrencies = cryptoCurrencies;
        this.claimedOffers = claimedOffers;
    }

    static fromJsonLowerCamelCase(json: any): Vault {
        return new Vault({
            cryptoCurrencies: json.cryptoCurrencies.map((cryptoCurrency: any) => CryptoCurrencyInfo.fromJsonLowerCamelCase(cryptoCurrency)),
            claimedOffers: json.claimedOffers.map((claimedOffer: any) => ClaimedOffer.fromJsonLowerCamelCase(claimedOffer)),
        });
    }

    static fromJsonUpperCase(json: any): Vault {
        return new Vault({
            cryptoCurrencies: json.CryptoCurrencies.map((cryptoCurrency: any) => CryptoCurrencyInfo.fromJsonUpperCamelCase(cryptoCurrency)),
            claimedOffers: json.ClaimedOffers.map((claimedOffer: any) => ClaimedOffer.fromJsonUpperCase(claimedOffer)),
        });
    }
}

export default Vault;

