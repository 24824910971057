import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LocaleService from './services/locale_service';
import * as Sentry from '@sentry/react';

function parseQueryStringToJson(query: string): Record<string, any> {
    const params = new URLSearchParams(query);
    const result: Record<string, any> = {};

    for (const [key, value] of params.entries()) {
        try {
            // Attempt to parse JSON for keys that might contain nested JSON strings
            result[key] =
                key === 'user' ? JSON.parse(decodeURIComponent(value)) : value;
        } catch {
            // If parsing fails, fallback to raw value
            result[key] = value;
        }
    }

    return result;
}

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/diggersgo\.com\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const { user } = parseQueryStringToJson(window.Telegram.WebApp.initData) || {};
const locale = user?.language_code || 'en';

await new LocaleService(locale, 'en').initialize();

if (window.visualViewport) {
    window.visualViewport.addEventListener('resize', () => {
        document.body.style.height = window.visualViewport!.height + 'px';
    });
}
// This will ensure user never overscroll the page
window.addEventListener('scroll', () => {
    if (window.scrollY > 0) window.scrollTo(0, 0);
});

// Override window.alert to log messages to console instead of showing them
window.alert = (message: string) => {
    console.log(message);
};

root.render(
    // <React.StrictMode>
    <App />
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
