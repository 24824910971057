import StandardHttpResponse from '../models/standard_http_response';
import StandardHttpAPI from '../standard_http_api';
import ValueContainer from '../../utils/value_container';
import { constants } from '../../utils/constants';
import RarityModel from '../models/rarity_model';

const API_URL = constants.API_URL;

class SettingsApi {
    static async getSettings(): Promise<StandardHttpResponse> {
        return await StandardHttpAPI.get({
            uri: `${API_URL}mobileUser/client_settings`,
            bearer: ValueContainer.token,
        });
    }

    static async getRarities(): Promise<RarityModel[] | undefined> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}settings/rarities`,
            bearer: ValueContainer.token,
        });

        if (response.isSuccess) {
            return response.response.map((rarity: any) =>
                RarityModel.fromJson(rarity)
            );
        }

        return undefined;
    }

    static async getRarityRequiredReferralCount(): Promise<StandardHttpResponse> {
        return await StandardHttpAPI.get({
            uri: `${API_URL}settings/RarityRequiredReferralCount`,
            bearer: ValueContainer.token,
        });
    }
}

export default SettingsApi;
