import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logPageView } from "./analytics";

const AnalyticsTracker: React.FC = () => {
    const location = useLocation();
  
    useEffect(() => {
      logPageView(location.pathname);
    }, [location.pathname]);
  
    return null;
  }

export default AnalyticsTracker;