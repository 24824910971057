import React from 'react';
import { hapticFeedback, init } from '@telegram-apps/sdk-react';

type ImpactStyle = 'light' | 'medium' | 'heavy' | 'rigid' | 'soft';
type NotificationStyle = 'error' | 'success' | 'warning';
type HapticFeedbackType = 'impact' | 'notification' | 'selection';
type HapticFeedbackStyle = ImpactStyle | NotificationStyle;

interface HapticButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  hapticFeedbackType?: HapticFeedbackType;
  hapticFeedbackStyle?: HapticFeedbackStyle;
  children: React.ReactNode;
}

const HapticButton: React.FC<HapticButtonProps> = ({
    onClick,
    hapticFeedbackType = 'impact',
    hapticFeedbackStyle = 'medium',
    children,
    ...props
  }) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (hapticFeedbackType === 'impact' && hapticFeedback.impactOccurred.isAvailable()) {
          hapticFeedback.impactOccurred(hapticFeedbackStyle as ImpactStyle);
        }
        else if (hapticFeedbackType === 'notification' && hapticFeedback.notificationOccurred.isAvailable()) {
            hapticFeedback.notificationOccurred(hapticFeedbackStyle as NotificationStyle);
          } 
        else {
            if(hapticFeedback.selectionChanged.isAvailable())
                hapticFeedback.selectionChanged();
        }
    
        if (onClick) {
          onClick(e);
        }
      };

    return (
        <button onClick={handleClick} {...props}>
          {children}
        </button>
      );

  }

export default HapticButton;