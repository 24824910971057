export default function formatNumber(value: number | undefined): string {
    if (value === undefined || value === null) {
        return '0';
    }

    if (value >= 1_000_000) {
        return (value / 1_000_000).toFixed(2) + 'M';
    } else if (value >= 1_000) {
        return (value / 1_000).toFixed(2)+ 'K';
    } else {
        return value.toString();
    }
}