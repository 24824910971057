import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import styles from "./sweet_alert.module.css";
import React from "react";

type SweetAlertPopupProps = {
    type: "error" | "info" | "success";
    title: string;
    message: string;
    onClose?: () => void; // Optional callback for when the popup is closed
  };
  
  const SweetAlertPopup: React.FC<SweetAlertPopupProps> = ({ type, title, message }) => {
    const MySwal = withReactContent(Swal);
  
    /**
     * Function to show the SweetAlert2 popup.
     */
    const showPopup = () => {
      MySwal.fire({
        icon: type,
        title: title,
        html: `<p class=${styles.text}>${message}</p>`,
        customClass: {
          popup: styles.container,
          input: styles.input,
          title: styles.title,
          confirmButton: styles.confirmButton,
          cancelButton: styles.cancelButton,
          container: styles.swalBackdrop,
        },
      });
    };
  
    /**
     * Helper to capitalize the first letter of a string.
     */
    const capitalizeFirstLetter = (str: string) =>
      str.charAt(0).toUpperCase() + str.slice(1);
  
    return null; // This component doesn't render anything
  };
  
  export default SweetAlertPopup;
  
  export const showSweetAlert = (type: "error" | "info" | "success", title: string, message: string) => {
    const MySwal = withReactContent(Swal);
  
    MySwal.fire({
      icon: type,
      title: title,
      html: `<p class=${styles.text}>${message}</p>`,
      customClass: {
        popup: styles.container,
        input: styles.input,
        title: styles.title,
        confirmButton: styles.confirmButton,
        cancelButton: styles.cancelButton,
        container: styles.swalBackdrop,
      },
    });
  };

  export const showSweetAlertChoice = (
    type: "error" | "info" | "success",
    title: string,
    message: string,
    confirmText: string,
    cancelText: string,
    onConfirm: () => void
  ) => {
    const MySwal = withReactContent(Swal);
  
    MySwal.fire({
      icon: type,
      title: title,
      html: `<p class=${styles.text}>${message}</p>`,
      showCancelButton: true,
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      customClass: {
        popup: styles.container,
        input: styles.input,
        title: styles.title,
        confirmButton: styles.confirmButton,
        cancelButton: styles.cancelButton,
        container: styles.swalBackdrop,
      },
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm();
      }
    });
  };