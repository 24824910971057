import { TimeDuration } from 'typed-duration';
import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import { NavigateFunction } from 'react-router-dom';
import Completer from '../../component_container/utilities/completer';

import { search_params_keys } from '../../../utils/constants';
import ContainerHelper from '../../component_container/utilities/container_helper';

type NavigationComponentSubscriber = () => void;

class NavigationComponent extends Component {
    private _subscribers: NavigationComponentSubscriber[] = [];

    addSubscriber(subscriber: NavigationComponentSubscriber): void {
        this._subscribers.push(subscriber);
    }

    removeSubscriber(subscriber: NavigationComponentSubscriber): void {
        this._subscribers = this._subscribers.filter((s) => s !== subscriber);
    }

    private _notifySubscribers(): void {
        this._subscribers.forEach((subscriber) => subscriber());
    }

    private _navigateFunction: NavigateFunction | undefined;
    private _locationPathname: string | undefined;
    private _search: string | undefined;
    private _selectedIndex: number = 0;
    private _visible: boolean = false;
    private _visibilityPreference: boolean = true;
    private _navigationBarHeight: number = 0;
    private _initialSearchParams: URLSearchParams | undefined;

    private _navigationBarHeightCompleter: Completer<void> =
        new Completer<void>();

    private _initialSearchParamsCompleter: Completer<URLSearchParams> =
        new Completer<URLSearchParams>();

    get initialSearchParamsCompleter(): Completer<URLSearchParams> {
        return this._initialSearchParamsCompleter;
    }

    get initialSearchParams(): URLSearchParams | undefined {
        return this._initialSearchParams;
    }

    set navigationBarHeight(navigationBarHeight: number) {
        if (navigationBarHeight === this._navigationBarHeight) {
            return;
        }

        this._navigationBarHeight = navigationBarHeight;
        this._notifySubscribers();

        if (!this._navigationBarHeightCompleter.resolved) {
            this._navigationBarHeightCompleter.resolve();
        }
    }

    get navigationBarHeight(): number {
        return this._navigationBarHeight;
    }

    set search(search: string) {
        this._search = search;
        const searchParams = new URLSearchParams(search);

        if (!this._initialSearchParams) {
            this._initialSearchParams = searchParams;
            if (!this._initialSearchParamsCompleter.resolved) {
                this._initialSearchParamsCompleter.resolve(
                    this._initialSearchParams
                );
            }
        }
    }

    set locationPathname(locationPathname: string) {
        this._locationPathname = locationPathname;

        if (this._locationPathname === '/') {
            this._selectedIndex = 0;
            this._visible = true;
            this._notifySubscribers();
            return;
        }

        if (this._locationPathname.includes('/rewards')) {
            this._selectedIndex = 1;
            this._visible = true;
            ContainerHelper.getOffersComponent().then((offersComponent) => {
                offersComponent.markNewAcquisitionAsSeen();
            });
            this._notifySubscribers();
            return;
        }

        if (this._locationPathname.includes('/friends')) {
            this._selectedIndex = 2;
            this._visible = true;
            this._notifySubscribers();
            return;
        }

        if (this._locationPathname.includes('/quests')) {
            this._selectedIndex = 3;
            this._visible = true;
            this._notifySubscribers();
            return;
        }

        if (this._locationPathname.includes('/profile')) {
            this._selectedIndex = 4;
            this._visible = false;
            this._notifySubscribers();
            return;
        }
    }

    get selectedIndex(): number {
        return this._selectedIndex;
    }

    get visible(): boolean {
        return this._visible && this._visibilityPreference;
    }

    set visibilityPreference(visibilityPreference: boolean) {
        this._visibilityPreference = visibilityPreference;
        this._notifySubscribers();
    }

    navigateTo(path: string): void {
        if (this._navigateFunction) {
            this._navigateFunction(path, {
                replace: true,
            });
        }
    }

    private _navigateTo(path: string): void {
        if (this._navigateFunction) {
            this._navigateFunction(path, {
                replace: true,
            });
        }
    }

    navigateToIndex(index: number): void {
        if (this._navigateFunction) {
            switch (index) {
                case 0:
                    this._navigateTo('/');
                    break;
                case 1:
                    this._navigateTo('/rewards');
                    break;
                case 2:
                    this._navigateTo('/friends');
                    break;
                case 3:
                    this._navigateTo('/quests');
                    break;
                case 4:
                    this._navigateTo('/profile');
                    break;
                default:
                    break;
            }
        }
    }

    goBack(): void {
        const pathParts = this._locationPathname?.split('/');
        if (pathParts) {
            pathParts.pop();
            const newPath = pathParts.join('/');
            this._navigateTo(newPath);
        }
    }

    private _loadCompleter: Completer<void> = new Completer<void>();

    setNavigateFunction(navigateFunction: NavigateFunction): void {
        this._navigateFunction = navigateFunction;
        if (!this._loadCompleter.resolved) {
            this._loadCompleter.resolve();
        }
    }

    get type(): Function {
        return NavigationComponent;
    }
    get name(): string {
        return 'Navigation Component';
    }
    async load(): Promise<ComponentError[]> {
        await Promise.all([
            this._loadCompleter.promise,
            this._navigationBarHeightCompleter.promise,
        ]);

        return [];
    }
    async onUnload(): Promise<void> {}
    async onPause(): Promise<void> {}
    async onResume(): Promise<void> {}
    update(sinceLastUpdate: TimeDuration): void {}
}

export default NavigationComponent;
