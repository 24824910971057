import React from 'react';
import ContainerHelper from '../../component_system/component_container/utilities/container_helper';
import ValueContainer from '../../utils/value_container';
import styles from './profile_page.module.css';
import Images from '../../component_system/components/preload/images';
import useFetchComponent from '../../component_system/component_container/utilities/use_fetch_hook';
import LevelProgressBar from '../components/level_progress_bar';
import useObservable from '../../utils/observable/use_observable_hook';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import WalletType from '../../apis/models/profile/wallet_type';
import StandardHttpResponse from '../../apis/models/standard_http_response';

const ProfilePage = () => {
    const currentLevel = useObservable(ValueContainer.currentLevelObservable);
    const currentLevelExperience = useObservable(
        ValueContainer.currentLevelExperienceObservable
    );
    const experienceUntilNextLevel = useObservable(
        ValueContainer.experienceUntilNextLevelObservable
    );
    const characterImageBase64 = useObservable(
        ValueContainer.characterImageBase64Observable
    );
    const totalSteps = useObservable(ValueContainer.totalStepsObservable);
    const totalPurchasedOffers = useObservable(
        ValueContainer.totalPurchasedOffersObservable
    );
    const username = useObservable(ValueContainer.usernameObservable);
    const totalFriendsCount = useObservable(
        ValueContainer.totalFriendsCountObservable
    );

    const evmWalletAddress = useObservable(ValueContainer.evmWalletObservable);
    const solanaWalletAddress = useObservable(
        ValueContainer.solanaWalletObservable
    );
    const tonWalletAddress = useObservable(ValueContainer.tonWalletObservable);

    const navigationComponent = useFetchComponent(
        ContainerHelper.getNavigationComponent
    );

    const authComponent = useFetchComponent(
        ContainerHelper.getAuthenticationComponent
    );

    const experienceGained =
        (currentLevelExperience ?? 1) - (experienceUntilNextLevel ?? 1);

    // Wallet validation regex patterns
    const walletRegex = {
        Solana: /^[1-9A-HJ-NP-Za-km-z]{32,44}$/, // Solana public key format
        EVM: /^0x[a-fA-F0-9]{40}$/, // Ethereum-style addresses
        TON: /^[a-zA-Z0-9_-]{48,64}$/, // TON wallet format (base64/URL-safe)
    };

    const getRegex = (walletType: WalletType) => {
        switch (walletType) {
            case WalletType.Solana:
                return walletRegex.Solana;
            case WalletType.EVM:
                return walletRegex.EVM;
            case WalletType.TON:
                return walletRegex.TON;
            default:
                return null;
        }
    };

    async function saveWalletAddress(
        walletType: WalletType,
        walletAddress: string
    ) {
        try {
            await authComponent?.updateWallet(walletType, walletAddress);
            return { success: true, message: 'Wallet saved' };
        } catch (error) {
            var errorData = error as StandardHttpResponse;
            if (errorData && errorData.hasErrorMessage) {
                return { success: false, message: errorData.errorMessage };
            }
            return { success: false, message: (error as Error).message };
        }
    }

    const MySwal = withReactContent(Swal);
    function openWalletPopup(
        walletType: WalletType,
        walletAddress: string | undefined
    ): void {
        MySwal.fire({
            title: `Enter your ${walletType} wallet`,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off',
            },
            inputValue: walletAddress,
            showCancelButton: true,
            confirmButtonText: 'Save wallet',
            showLoaderOnConfirm: true,
            customClass: {
                popup: styles.container,
                input: styles.input,
                title: styles.title,
                confirmButton: styles.confirmButton,
                cancelButton: styles.cancelButton,
                container: styles.swalBackdrop,
            },
            preConfirm: (inputValue) => {
                // Validate the input based on wallet type
                if (!getRegex(walletType)?.test(inputValue)) {
                    MySwal.showValidationMessage(
                        `<p class=${styles.errorText}>Invalid ${walletType} wallet address. Please enter a valid one.</p>`
                    );
                    return false;
                }

                // Return the input value to handle in `.then()`
                return inputValue;
            },
            allowOutsideClick: () => !MySwal.isLoading(),
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Save the wallet address;
                try {
                    const response = await saveWalletAddress(
                        walletType,
                        result.value
                    );
                    if (response.success) {
                        // Show success message
                        MySwal.fire({
                            icon: 'success',
                            title: 'Wallet saved successfully!',
                            html: `<p class=${styles.text}>Your ${walletType} wallet address has been saved.</p>`,
                            customClass: {
                                popup: styles.container,
                                input: styles.input,
                                title: styles.title,
                                confirmButton: styles.confirmButton,
                                cancelButton: styles.cancelButton,
                                container: styles.swalBackdrop,
                            },
                        });
                    } else {
                        throw new Error(
                            response.message || 'Failed to save wallet.'
                        );
                    }
                } catch (error) {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error',
                        html: `<p class=${styles.text}>${(error as Error).message}</p>`,
                        customClass: {
                            popup: styles.container,
                            input: styles.input,
                            title: styles.title,
                            confirmButton: styles.confirmButton,
                            cancelButton: styles.cancelButton,
                            container: styles.swalBackdrop,
                        },
                    });
                }
            }
            // else if (result.dismiss === Swal.DismissReason.cancel) {
            //    MySwal.fire("Cancelled", "Wallet address not saved", "info");
            // }
        });
    }

    return (
        <>
            <div className={styles.pageBg}>
                <img
                    src={Images.Backgrounds.AnimatedBackground}
                    className={styles.animatedImage}
                    alt=""
                />
            </div>

            <div className={styles.pageContent}>
                <div
                    className={styles.titleController}
                    onClick={() => navigationComponent?.goBack()}
                >
                    <div className={styles.backBtn}>
                        <img src={Images.Profile.BackButton} alt="" />
                    </div>
                    <h2 className={styles.pageTitle}>Profile</h2>
                </div>
                <div className={styles.profilePanel}>
                    <div className={styles.profileImage}
                      style={{backgroundImage: `url(data:image/png;base64,${characterImageBase64})`}}
                      >
                        <img
                            src={Images.Profile.ProfileOverlay}
                            className={styles.overlay}
                            alt="User avatar"
                        />
                        <div className={styles.backdrop}></div>
                    </div>
                    <h3 className={styles.profileName}>
                        {username ?? 'Username'}
                    </h3>

                    <LevelProgressBar
                        width={100}
                        height={25}
                        borderRadius={6}
                    />
                    <div className={styles.levelInfo}>
                        <p className={styles.left}>
                            {'level'.tr() + ` ${currentLevel ?? 1}`}
                        </p>
                        <p
                            className={styles.right}
                        >{`${experienceGained.toFixed(0)} / ${(currentLevelExperience ?? 1).toFixed(0)} XP`}</p>
                    </div>
                </div>
                <div className={styles.divider}></div>
                <p className={styles.label}>Wallets</p>
                <div className={styles.wallets}>
                    <div
                        className={`${styles.wallet} ${styles.sol} ${solanaWalletAddress ? styles.connected : ''}`}
                        style={{
                            backgroundImage: solanaWalletAddress
                                ? `url(${Images.Profile.SolanaWalletConnect})`
                                : `url(${Images.Profile.SolanaWallet})`,
                        }}
                        onClick={() =>
                            openWalletPopup(
                                WalletType.Solana,
                                solanaWalletAddress
                            )
                        }
                    ></div>
                    <div
                        className={`${styles.wallet} ${styles.evm} ${styles.middle} ${evmWalletAddress ? styles.connected : ''}`}
                        style={{
                            backgroundImage: evmWalletAddress
                                ? `url(${Images.Profile.EvmWalletConnect})`
                                : `url(${Images.Profile.EvmWallet})`,
                        }}
                        onClick={() =>
                            openWalletPopup(WalletType.EVM, evmWalletAddress)
                        }
                    ></div>
                    <div
                        className={`${styles.wallet} ${styles.ton} ${tonWalletAddress ? styles.connected : ''}`}
                        style={{
                            backgroundImage: tonWalletAddress
                                ? `url(${Images.Profile.TonWallet})`
                                : `url(${Images.Profile.TonWallet})`,
                        }}
                        onClick={() =>
                            openWalletPopup(WalletType.TON, tonWalletAddress)
                        }
                    ></div>
                </div>
                <div className={styles.divider}></div>
                <p className={styles.label}>Stats</p>
                <div className={styles.referralStats}>
                    <div
                        className={`${styles.referralStat} ${styles.referralStatFirst}`}
                    >
                        <img
                            src={Images.Icons.IconRef}
                            className={styles.statIcon}
                            alt={'steps'.tr()}
                        />
                        <p>
                            <span>{'steps'.tr()}</span>
                            <br />
                            {totalSteps ?? 0}
                        </p>
                    </div>
                    <div className={styles.referralStat}>
                        <img
                            src={Images.Icons.IconRef}
                            className={styles.statIcon}
                            alt={'diggs'.tr()}
                        />
                        <p>
                            <span>{'diggs'.tr()}</span>
                            <br />
                            {totalPurchasedOffers ?? 0}
                        </p>
                    </div>
                    <div
                        className={`${styles.referralStat} ${styles.referralStatFirst}`}
                    >
                        <img
                            src={Images.Icons.IconRef}
                            className={styles.statIcon}
                            alt={'friends'.tr()}
                        />
                        <p>
                            <span>{'friends'.tr()}</span>
                            <br />
                            {totalFriendsCount ?? 0}
                        </p>
                    </div>
                </div>
                <div className={styles.divider}></div>
                <div className={styles.achievements}>
                    <p className={styles.label}>Achievements</p>
                    <div
                        className={styles.achievement}
                        style={{
                            backgroundImage: `url(${Images.Profile.AchievementBanner})`,
                        }}
                    ></div>
                    <div
                        className={`${styles.achievement} ${styles.middle}`}
                        style={{
                            backgroundImage: `url(${Images.Profile.AchievementBanner})`,
                        }}
                    ></div>
                    <div
                        className={styles.achievement}
                        style={{
                            backgroundImage: `url(${Images.Profile.AchievementBanner})`,
                        }}
                    ></div>
                    <div
                        className={styles.achievement}
                        style={{
                            backgroundImage: `url(${Images.Profile.AchievementBanner})`,
                        }}
                    ></div>
                    <div
                        className={`${styles.achievement} ${styles.middle}`}
                        style={{
                            backgroundImage: `url(${Images.Profile.AchievementBanner})`,
                        }}
                    ></div>
                    <div
                        className={styles.achievement}
                        style={{
                            backgroundImage: `url(${Images.Profile.AchievementBanner})`,
                        }}
                    ></div>
                </div>
            </div>
        </>
    );
};

export default ProfilePage;
