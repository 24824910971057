import WalletType from "./wallet_type";

class Wallet {
    walletType: WalletType;
    walletAddress: string;
    constructor({ walletType, walletAddress }: { walletType: WalletType, walletAddress: string }) {
        this.walletType = walletType;
        this.walletAddress = walletAddress;
    }
    static fromJsonLowerCamelCase(json: any): Wallet {
        return new Wallet({
            walletType: json.walletType,
            walletAddress: json.walletAddress,
           
        });
    }
    static fromJsonUpperCase(json: any): Wallet {
        return new Wallet({
            walletType: json.WalletType,
            walletAddress: json.WalletAddress,
            
        });
    }
}

export default Wallet;