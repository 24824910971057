import React, { useEffect, useRef, useState } from 'react';
import styles from './vault_page.module.css';
import useFetchComponent from '../../component_system/component_container/utilities/use_fetch_hook';
import ContainerHelper from '../../component_system/component_container/utilities/container_helper';
import OfferComponent from '../../component_system/components/offer/offer_component';
import ValueContainer from '../../utils/value_container';
import useObservable from '../../utils/observable/use_observable_hook';
import HapticButton from '../components/button/haptic_button';
import Images from '../../component_system/components/preload/images';
import CryptoCurrencyInfo from '../../apis/offer_api/crypto_currency_info';
import { showSweetAlert } from '../components/popup/sweet_alert';
import formatNumber from '../../utils/currency_formatter';
import ClaimedOffer from '../../apis/offer_api/claimed_offers';

const VaultPage = () => {
    const MIN_CLAIM_LEVEL = 100;

    const navigationComponent = useFetchComponent(
        ContainerHelper.getNavigationComponent
    );

    const coins = useObservable(ValueContainer.coinsObservable);
    const currentLevel = useObservable(ValueContainer.currentLevelObservable);

    const [aquiredOffers, setAquiredOffers] = useState<CryptoCurrencyInfo[]>([]);
    const [claimedOffers, setClaimedOffers] = useState<ClaimedOffer[]>([]);
    const offerComponentRef = useRef<OfferComponent | null>(null);

    const offerComponent = useFetchComponent(
        ContainerHelper.getOffersComponent
    );

    useEffect(() => {
        const subscriber = () => {
            if (offerComponentRef.current) {
                setAquiredOffers([...offerComponentRef.current.acquiredOffers]);
                setClaimedOffers([...offerComponentRef.current.claims]);
              
            }
        };

        const setup = async () => {
            offerComponentRef.current =
                await ContainerHelper.getOffersComponent();
            // Subscribe to updates
            offerComponentRef.current.registerSubscriber(subscriber);
            setAquiredOffers([...offerComponentRef.current.acquiredOffers]);
            setClaimedOffers([...offerComponentRef.current.claims]);        
        };

        setup();

        // Cleanup on unmount
        return () => {
            if (offerComponentRef.current) {
                offerComponentRef.current.unregisterSubscriber(subscriber);
            }
        };
    }, []);

    function claimAction(offer: CryptoCurrencyInfo): void {

        const result = canClaim(offer);
        if (!result.canClaim) {
            showSweetAlert('info', 'Claim', result.errorMessage ?? 'Error');
            return;
        }
        offerComponentRef.current?.claim(offer.cryptoCurrency);


        //showSweetAlert(
        //         'info',
        //        'Claim',
        //        `Please wait for the claim feature to go live.`
        //    )

    }

    function canClaim(offer: CryptoCurrencyInfo): { canClaim: boolean, errorMessage?: string } {
        if((offer.cryptoCurrencyAmount??0) <= 0)
            return {canClaim: false, errorMessage: 'No coins to claim'};
        if (offer.cryptoCurrency === 'DIG') {
            return {canClaim: false, errorMessage: 'DIG is not claimable yet'};
        }
        if ((currentLevel ?? 0) < MIN_CLAIM_LEVEL) {
            return {canClaim: false, errorMessage: `You can claim at level ${MIN_CLAIM_LEVEL} with 10 USD worth of ${offer.cryptoCurrency}.`};
        } 
        if (!enoughCoins(offer)) {
            return {canClaim: false, errorMessage: `You can claim at level ${MIN_CLAIM_LEVEL} with 10 USD worth of ${offer.cryptoCurrency}.`};
        }       
        return {canClaim: true};
    }

    function enoughCoins(offer: CryptoCurrencyInfo): boolean {
        switch (offer.cryptoCurrency) {
            case 'DIG':
                return false;
            case 'CT':
                return (offer.cryptoCurrencyAmount ?? 0) >= 6000;     // 9.64 USD      
            case 'PEPE':
                return (offer.cryptoCurrencyAmount ?? 0) >= 2500000000; //9.8 USD
            case 'SHIB':
                return (offer.cryptoCurrencyAmount ?? 0) >= 300000; //9.58 USD
            case 'BabyDoge':
                return (offer.cryptoCurrencyAmount ?? 0) >= 2300000000; // 9,83 USD
            case 'DOGE':
                return (offer.cryptoCurrencyAmount ?? 0) >= 20; // 9.26 USD           
            case 'DOGS':
                return (offer.cryptoCurrencyAmount ?? 0) >= 11500; // 9.76 USD
            case 'FLOKI':
                return (offer.cryptoCurrencyAmount ?? 0) >= 38000; // 9.82 USD
            default:
                return false;
        }
    }

    return (
        <>
            <div className={styles.pageBg}>
                <img
                    src={Images.Backgrounds.AnimatedBackground}
                    className={styles.animatedImage}
                    alt=""
                />
            </div>
            <div className={styles.pageContent}>
                <h2 className={styles.pageTitle}>Vault</h2>

                <div key={-1} className={`${styles.questPanel} `}>
                    <img
                        src={Images.Icons.DiggersGoCoin}
                        className={styles.icon}
                        alt=""
                    />
                    <h5 className={styles.tokenTitle}>{'DIG'}</h5>
                    <div className={styles.actions}>
                        <p>{formatNumber(coins)}</p>
                        <HapticButton
                            hapticFeedbackType="impact"
                            hapticFeedbackStyle="light"
                            //  disabled={true}
                            type="button"
                            className={`${styles.claim} ${styles.noCoins}`}
                            onClick={() =>
                                claimAction({
                                    cryptoCurrency: 'DIG',
                                    name: '',
                                    description: '',
                                    coverUrl: '',
                                    cryptoCurrencyAmount: coins,
                                })
                            }
                        >
                            Claim
                        </HapticButton>
                    </div>

                    {offerComponentRef.current?.newAcquisition &&
                        offerComponentRef.current?.newAcquisition.cryptoCurrency ===
                            'DIG' && (
                            <span className={styles.questPanelBadge}>!</span>
                        )}
                </div>

                {aquiredOffers &&
                aquiredOffers.length > 0
                    ? aquiredOffers
                          .filter((o) => o.cryptoCurrency !== 'DIG')
                          .map((offer, index) => (
                              <div
                                  key={index}
                                  className={`${styles.questPanel} ${styles.vault} ${offer.name === undefined ? styles.nOCoin : ''}`}
                              >
                                  <img
                                      src={
                                          offer.coverUrl ||
                                          Images.Icons.DiggersGoCoin
                                      }
                                      className={styles.icon}
                                      alt=""
                                  />
                                  <h5 className={styles.tokenTitle}>
                                      {offer.cryptoCurrency}
                                  </h5>
                                  <div className={styles.actions}>
                                      <p>
                                          {formatNumber(
                                              offer.cryptoCurrencyAmount
                                          )}
                                      </p>
                                      <HapticButton
                                          hapticFeedbackType="impact"
                                          hapticFeedbackStyle="light"
                                          type="button"
                                          disabled={offer.cryptoCurrencyAmount??0 >0 ? false: true}
                                          className={`${styles.claim} ${canClaim(offer).canClaim ? '' : styles.noCoins }`}
                                          onClick={() => claimAction(offer)}
                                      >
                                          Claim
                                      </HapticButton>
                                  </div>

                                  {offerComponentRef.current?.newAcquisition &&
                                      offerComponentRef.current?.newAcquisition
                                          .cryptoCurrency ===
                                          offer.cryptoCurrency && (
                                          <span
                                              className={styles.questPanelBadge}
                                          >
                                              !
                                          </span>
                                      )}
                              </div>
                          ))
                    : null}
                <div className={styles.divider}></div>
                <p className={styles.label}>Claims</p>
                {claimedOffers &&
                claimedOffers.length > 2
                    ? claimedOffers.map((offer, index) => (
                          <div
                              key={index}
                              className={`${styles.questPanel} ${styles.vault}`}
                          >
                              <img
                                  src={offer.coverUrl}
                                  className={styles.icon}
                                  alt=""
                              />
                              <h5 className={styles.tokenTitle}>
                                  {`${formatNumber(offer.cryptoCurrencyAmount)}  ${offer.cryptoCurrency}`}
                              </h5>
                              <div className={styles.status}>
                                  <p>
                                      {offer.status}
                                  </p>
                              </div>
                          </div>
                      ))
                    : <p className={styles.label} style={{fontSize:"small"}}>No claims yet</p>}
                

                <div style={{ height: 120 }} />
            </div>
        </>
    );
};

export default VaultPage;
